import axios from 'axios'
import toast from 'react-hot-toast'
import { application } from '../../../../../redux/store'
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { getReceiptData } from './slice'
import { ReceiptTypes } from './types'

function* getReceiptHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/receipts?page=${page}`)
    )
    yield put(getReceiptData(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* ReceiptSagas() {
  yield all([takeEvery(ReceiptTypes.GET_RECEIPT, getReceiptHandler)])
}
