import axios from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { UserSearchTypes } from './types'
import {
  deleteResidentPayment,
  getNotification,
  getPayments,
  getScrollPayments,
  getTutorial,
  getUser,
  showResidents,
} from './slice'
import toast from 'react-hot-toast'
import { application } from '../../../../../redux/store'

function* getUserHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/me`))
    const user_data = {
      id: response.data.data.id,
      name: response.data.data.name,
      surname: response.data.data.surname,
      email: response.data.data.email,
      phone: response.data.data.phone,
      assoc_name: response.data.data.assoc_name,
      is_branch: response.data.data.is_branch,
      branchs: response.data.data.branchs,
      logo:
        response.data.data.logo === ''
          ? '/image/home.jfif'
          : response.data.data.logo,
    }
    yield put(getUser(user_data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showResidentsHandler() {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/main?key=null`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    //yield put(showResidents(filterRecords))
  } catch (error) {}
}
function* searchResidentsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/main?key=${payload.key}`)
    )

    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    if (payload.key === null) {
      yield put(showResidents([]))
    } else {
      yield put(showResidents(filterRecords))
    }
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* getTutorialHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/tutorial`))
    yield put(getTutorial(response.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateTutorialHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/tutorial`, {
        step: payload.step,
        is_completed: payload.is_complete,
      })
    )
    yield put(getTutorial(response.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* isFirstTutorialHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/tutorial`, {
        is_first: payload.is_first,
        step: payload.step,
      })
    )
    yield put(getTutorial(response.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* getNotificationsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/user/messages`, {
        params: { page: payload.page },
      })
    )
    yield put(getNotification(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* getPaymentsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/user/transfer`, {
        params: { page: payload.page },
      })
    )
    if (payload.page === 1) {
      yield put(getPayments(response.data.data.Records))
    } else {
      yield put(getScrollPayments(response.data.data.Records))
    }
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* finishedTutorialHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `https://api.telegram.org/bot5792562277:AAH_NXUBxyukrmjNCRafcGNKfMOpzIDvoig/sendMessage`,
        {
          params: {
            chat_id: '-1001659287980',
            text: `
İlk Kurulumu Yaptı.
SMS Hediye Tanımla.
Üye Id : ${payload?.id}
Site adı : ${payload.assoc_name}
Yetkili adı: ${payload?.name} ${payload?.surname}
Tel : ${payload?.phone}
          `,
          },
        }
      )
    )
    toast.success(
      'Başarılı bir şekilde adımları tamamladınız en kısa sürede size ulaşacağız.'
    )
  } catch (error) {
    toast.error(error)
  }
}

export function* UserSearchSagas() {
  yield all([
    takeEvery(UserSearchTypes.GET_USER, getUserHandler),
    takeEvery(UserSearchTypes.SHOW_USERS, showResidentsHandler),
    takeEvery(UserSearchTypes.SEARCH_USERS_KEY, searchResidentsHandler),
    takeEvery(UserSearchTypes.GET_TUTORIAL, getTutorialHandler),
    takeEvery(UserSearchTypes.UPDATE_TUTORIAL, updateTutorialHandler),
    takeEvery(UserSearchTypes.IS_FIRST_TUTORIAL, isFirstTutorialHandler),
    takeEvery(UserSearchTypes.FINISHED_TUTORIAL, finishedTutorialHandler),
    takeEvery(UserSearchTypes.USER_NOTIFICATIONS, getNotificationsHandler),
    takeEvery(UserSearchTypes.USER_PAYMENT, getPaymentsHandler),
  ])
}
