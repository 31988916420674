import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

const initialState = {
    accountActivities:{},
    showAccActivitiesTotal:{},
    is_filter_load:false
}

const AccountActivitiesSlice = createSlice({
    name:"accountActivitiesSlice",
    initialState:initialState,
    reducers: {
        filterLoad:(state,action)=>{
            state.is_filter_load = action.payload
        },
        showAccountActivities: (state, action) => {
            action.payload.map((item) => (
                item.start_date = moment(item.date).format("DD.MM.YYYY"),
                item.end_date = moment(item.date).format("DD.MM.YYYY")
            ))
            state.accountActivities = action.payload
        },
        showAccActivitiesTotal: (state, action) => {
            state.accActivitiesTotal = action.payload
        },
    }
})

export const { showAccountActivities, showAccActivitiesTotal ,filterLoad} = AccountActivitiesSlice.actions

export default AccountActivitiesSlice.reducer