import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { DueGroupTypes } from './types'
import toast from 'react-hot-toast'
import {
  deleteDueGroup,
  listTypes,
  list_apartment,
  showDueGroup,
} from './slice'

function* showDueGroupHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/debt/job?page=${page}`)
    )

    yield put(showDueGroup(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addDueGroupHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/job`, payload.values)
    )

    //yield put(addDueGroup(response.data.data));
    yield showDueGroupHandler(page)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateDueGroupHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/debt/job`, payload)
    )
    //yield put(updateDueGroup(payload));
    yield showDueGroupHandler({ page })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteDueGroupHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/debt/job`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield put(deleteDueGroup(payload.id))
    yield showDueGroupHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* listTypesHandler() {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/accounting/types`, {
        params: {
          is_print: true,
        },
      })
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(listTypes(filterRecords))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* listAparmentsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartments/by/building`, {
        building_id: [payload.id],
      })
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.identifier,
    }))

    yield put(list_apartment(filterRecords))
  } catch (error) {}
}
export function* DefDueGroupSagas() {
  yield all([
    takeEvery(DueGroupTypes.SHOW_DUE_GROUP, showDueGroupHandler),
    takeEvery(DueGroupTypes.ADD_DUE_GROUP, addDueGroupHandler),
    takeEvery(DueGroupTypes.UPDATE_DUE_GROUP, updateDueGroupHandler),
    takeEvery(DueGroupTypes.DELETE_DUE_GROUP, deleteDueGroupHandler),
    takeEvery(DueGroupTypes.LIST_TYPES, listTypesHandler),
    takeEvery(DueGroupTypes.LIST_APARTMENT, listAparmentsHandler),
  ])
}
