export const SelectServiceTypes = {
  SHOW_SEARCH_BUILDING: "SHOW_SEARCH_BUILDING",
  SHOW_SEARCH_BUILDING_KEY: "SHOW_SEARCH_BUILDING_KEY",
  SHOW_SEARCH_APARTMENT: "SHOW_SEARCH_APARTMENT",
  SHOW_SEARCH_APARTMENT_KEY: "SHOW_SEARCH_APARTMENT_KEY",
  SHOW_SEARCH_APARTMENT_SINGLE: "SHOW_SEARCH_APARTMENT_SINGLE",
  SHOW_SEARCH_APARTMENT_SINGLE_KEY: "SHOW_SEARCH_APARTMENT_SINGLE_KEY",
  SHOW_SEARCH_ACCOUNT: "SHOW_SEARCH_ACCOUNT",
  SHOW_SEARCH_ACCOUNT_KEY: "SHOW_SEARCH_ACCOUNT_KEY",
  SHOW_SEARCH_TYPE_KEY: "SHOW_SEARCH_TYPE_KEY",
  SHOW_SEARCH_TYPE: "SHOW_SEARCH_TYPE",
  SHOW_SEARCH_DEBT_GROUP: "SHOW_SEARCH_DEBT_GROUP",
  SHOW_SEARCH_DEBT_GROUP_KEY: "SHOW_SEARCH_DEBT_GROUP_KEY",
  SHOW_SEARCH_CURRENT: "SHOW_SEARCH_CURRENT",
  SHOW_SEARCH_CURRENT_KEY: "SHOW_SEARCH_CURRENT_KEY",
  SHOW_SEARCH_RESIDENTS: "SHOW_SEARCH_RESIDENTS",
  SHOW_SEARCH_RESIDENTS_KEY: "SHOW_SEARCH_RESIDENTS_KEY",
  SHOW_SEARCH_SMS_TEMPLATES: "SHOW_SEARCH_SMS_TEMPLATES",
  SHOW_SEARCH_SMS_TEMPLATES_KEY: "SHOW_SEARCH_SMS_TEMPLATES_KEY",
  SHOW_SEARCH_STAFF: "SHOW_SEARCH_STAFF",
  SHOW_SEARCH_STAFF_KEY: "SHOW_SEARCH_STAFF_KEY",
  SHOW_SEARCH_SURVEY: "SHOW_SEARCH_SURVEY",
  SHOW_SEARCH_SURVEY_KEY: "SHOW_SEARCH_SURVEY_KEY",
  SHOW_SEARCH_APARTMENT_NO: "SHOW_SEARCH_APARTMENT_NO",
  SHOW_SEARCH_APARTMENT_NO_KEY: "SHOW_SEARCH_APARTMENT_NO_KEY",
  SHOW_SEARCH_APARTMENT_NO_SINGLE: "SHOW_SEARCH_APARTMENT_NO_SINGLE",
  SHOW_SEARCH_APARTMENT_NO_SINGLE_KEY: "SHOW_SEARCH_APARTMENT_NO_SINGLE_KEY",
};
