import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  smsTemplates: [],
  isTemplateCreated: false,
};
const SmsTemplateSlice = createSlice({
  name: "SmsTemplateSlice",
  initialState: initialState,
  reducers: {
    showTemplate: (state, action) => {
      state.smsTemplates = action.payload;
    },
    addTemplate: (state, action) => {
      state.smsTemplates = action.payload;
    },
    isTemplateCreated: (state, action) => {
      state.isTemplateCreated = action.payload;
    },
  }
});
export const {
  addTemplate, showTemplate, isTemplateCreated
} = SmsTemplateSlice.actions;
export default SmsTemplateSlice.reducer;
