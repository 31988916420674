import axios from 'axios'
import moment from 'moment'
import toast from 'react-hot-toast'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import {
  addResident,
  deleteResident,
  detailResident,
  showResident,
  updateResident,
  showParents,
  addParents,
  showDebtReport,
  filterResident,
  updateParents,
  deleteParents,
  showAdvances,
  receiptData,
  isDeleteAction,
  filterLoad,
  handleResidentPost,
  addExcelResidentError,
} from './slice'
import { residentTypes } from './types'
import { deleteResidentPayment } from '../../../../../@core/layouts/components/navbar/store/slice'

function* showResidentHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident/all?page=${page}`)
    )
    yield put(showResident(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addResidentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident`, payload.data)
    )
    yield put(addResident(response.data.data.id))
    yield showResidentHandler(payload)
    yield put(handleResidentPost(true))
    toast.success(response.data.description, {
      position: 'top-center',
    })
  } catch (error) {
    yield put(addResident(0))
    yield put(handleResidentPost(false))
    toast.error(error.response.data.description)
  }
}

function* detailResidentHandler({ id }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/single?id=${id}`)
    )
    yield put(detailResident(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateResidentHandler({ payload }) {
  // backendle uyumsuz payloadlar uyumlu hale getirildi
  payload.phone = payload.phone.replaceAll(' ', '')
  payload.birth_day =
    (payload.birth_day && moment(payload.birth_day).format('DD.MM.YYYY')) || ''
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/resident`, payload)
    )
    yield put(updateResident(response.data.data))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteResidentHandler({ id }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/resident`, {
        data: {
          id: id,
        },
      })
    )
    yield put(deleteResident(id))
    yield put(isDeleteAction(true))
    toast.success(response.data.description)
  } catch (error) {
    yield put(isDeleteAction(false))
    toast.error(error.response.data.description)
  }
}

function* showParentsListHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/parents`, {
        params: { resident_id: payload.id, page: payload.page },
      })
    )

    yield put(showParents(response.data.data.Records))
  } catch (error) {}
}
function* addParentsHandler({ payload }) {
  payload.data.phone = payload.data.phone.replaceAll(' ', '')
  payload.data.national_id = parseInt(payload.data.national_id)
  payload.id = parseInt(payload.data.resident_id)
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident/parent`, payload.data)
    )
    yield put(addParents(response.data.data))
    yield showParents(payload)
    toast.success(response.data.description, {
      position: 'top-center',
    })
  } catch (error) {}
}
function* updateParentsHandler({ payload }) {
  payload.data.phone = payload.data.phone.replaceAll(' ', '')
  payload.data.national_id = parseInt(payload.data.national_id)
  payload.id = parseInt(payload.data.resident_id)
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/resident/parent`, payload.data)
    )
    yield put(updateParents(response.data.data))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteParentsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/resident/parent`, {
        data: {
          id: payload.parent_id,
        },
      })
    )
    yield put(deleteParents(payload.parent_id))
    const showPayload = {
      id: payload.resident_id,
      page: payload.page,
    }
    yield showParentsListHandler({ payload: showPayload })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* showDebtReportHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/debts`, {
        params: { resident_id: payload.id, page: payload.page },
      })
    )
    yield put(showDebtReport(response.data.data.Records))
  } catch (error) {}
}

function* updateDebtReportHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/debts`, {
        params: { resident_id: payload.id, page: payload.page },
      })
    )
    yield showDebtReportHandler({ payload })
  } catch (error) {}
}

function* deleteDebtReportHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/delete/debt/single`, {
        data: {
          id: payload.id,
          set_advance_payment: true,
        },
      })
    )
    const newPayload = {
      id: payload.resident_id,
      page: 1,
    }
    yield showDebtReportHandler({ payload: newPayload })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showAdvancesHandler({ payload, page }) {
  console.log(payload)
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/advance/payments?page=${page}&resident_id=${payload.resident_id}`
      )
    )
    yield put(showAdvances(response.data.data))
  } catch (error) {}
}

function* addAdvancesHandler({ payload, page }) {
  console.log(payload)
  console.log(page)
  payload.amount = parseInt(payload.amount)
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/advance/payments`, payload)
    )
    toast.success(response.data.description)
    console.log(page)
    yield showAdvancesHandler({ payload, page })
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateAdvancesHandler({ payload, page }) {
  payload.amount = parseInt(payload.amount)
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/advance/payments`, payload)
    )
    toast.success(response.data.description)
    const newPayload = {
      id: payload.resident_id,
    }
    yield showAdvancesHandler({ payload: newPayload, page })
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteAdvancesHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/advance/payments`, {
        data: {
          id: payload.id,
        },
      })
    )
    const showPayload = {
      resident_id: payload.resident_id,
      page: page,
    }
    yield showAdvancesHandler({ payload: showPayload, page })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showFilterResidentHandler({ payload, page }) {
  // uzunluğu sıfır olanları null olarak backende gönderiyoruz.
  payload.building_id =
    payload.building_id.length === 0 ? null : payload.building_id
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident/all?page=${page}`, {
        building_id:
          payload.building_id?.length > 0 ? payload.building_id : null,
        name: payload.name.length > 0 ? payload.name : null,
        phone: payload.phone.length > 0 ? payload.phone : null,
        gender: payload.gender.id,
        debt_remaining:
          payload.debt_remaining.length > 0 ? payload.debt_remaining : null,
        gender: payload.gender.length > 0 ? payload.gender : null,
      })
    )

    yield put(filterResident(response.data.data.Records))
    yield put(filterLoad(false))
  } catch (error) {}
}
function* filterResidentLoadHandler() {
  yield put(filterLoad(true))
}

function* deletePaymentsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/user/transfer/${payload.id}`)
    )
    yield put(deleteResidentPayment(payload.id))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addPaymentDebtHandler(data) {
  const payload = {
    accounting_type_id: data.payload.accounting_type_id,
    account_id: data.payload.account_id,
    amount: parseFloat(data.payload.amount),
    date: data.payload.date,
    debt_id: data.payload.id,
    description: data.payload.description,
  }
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/payment/debt`, payload)
    )
    const responseUser = yield call(() => axios.get(`${application.api}/me`))
    toast.success(response.data.description)
    const newPayload = {
      id: data.resident_id,
      page: 1,
    }

    yield put(
      receiptData({
        id: responseUser.data.data.id,
        remainingAmount: response.data.data.receipt.remainingAmount,
        logo: responseUser.data.data.logo,
        name: responseUser.data.data.name,
        surname: responseUser.data.data.surname,
        barcode: response.data.data.receipt.barcode,
        amount: response.data.data.receipt.amount,
      })
    )
    yield showDebtReportHandler({ payload: newPayload })
    if (data.is_notification) {
      yield deletePaymentsHandler({ payload: data.is_notification })
    }
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* pullAdvancesHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/advance/payments/withdraw/money`, {
        amount: payload.amount,
        resident_id: payload.resident_id,
      })
    )
    yield showAdvancesHandler({ payload, page })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* isWpActiveHandler({}) {
  try {
    const response = yield call(() => axios.get(`${application.api}/licance`))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addExcelResidentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartment/multi/resident`, payload)
    )
    toast.success(response.data.description)
    yield put(addExcelResidentError(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* residentSagas() {
  yield all([
    takeEvery(residentTypes.SHOW_RESIDENT, showResidentHandler),
    takeEvery(residentTypes.ADD_RESIDENT, addResidentHandler),
    takeEvery(residentTypes.DETAIL_RESIDENT, detailResidentHandler),
    takeEvery(residentTypes.UPDATE_RESIDENT, updateResidentHandler),
    takeEvery(residentTypes.DELETE_RESIDENT, deleteResidentHandler),
    takeEvery(residentTypes.SHOW_PARENTS, showParentsListHandler),
    takeEvery(residentTypes.ADD_PARENTS, addParentsHandler),
    takeEvery(residentTypes.UPDATE_PARENTS, updateParentsHandler),
    takeEvery(residentTypes.DELETE_PARENTS, deleteParentsHandler),
    takeEvery(residentTypes.SHOW_DEBT_REPORT, showDebtReportHandler),
    takeEvery(residentTypes.UPDATE_DEBT, updateDebtReportHandler),
    takeEvery(residentTypes.DELETE_DEBT_RESIDENT, deleteDebtReportHandler),
    takeEvery(residentTypes.PULL_ADVANCES, pullAdvancesHandler),
    takeEvery(residentTypes.SHOW_FILTER_RESIDENT, showFilterResidentHandler),
    takeEvery(residentTypes.SHOW_FILTER_RESIDENT, filterResidentLoadHandler),
    takeEvery(residentTypes.SHOW_ADVANCES, showAdvancesHandler),
    takeEvery(residentTypes.ADD_ADVANCES, addAdvancesHandler),
    takeEvery(residentTypes.UPDATE_ADVANCES, updateAdvancesHandler),
    takeEvery(residentTypes.DELETE_ADVANCES, deleteAdvancesHandler),
    takeEvery(residentTypes.ADD_PAYMENT_DEBT_RESIDENT, addPaymentDebtHandler),
    takeEvery(residentTypes.CHECK_LICENCE, isWpActiveHandler),
    takeEvery(residentTypes.ADD_EXCEL_RESIDENT, addExcelResidentHandler),
  ])
}
