export const residentTypes = {
  SHOW_RESIDENT: "SHOW_RESIDENT",
  ADD_RESIDENT: "ADD_RESIDENT",
  DETAIL_RESIDENT: "DETAIL_RESIDENT",
  UPDATE_RESIDENT: "UPDATE_RESIDENT",
  DELETE_RESIDENT: "DELETE_RESIDENT",
  RESIDENT_DETAIL_DEBT: "RESIDENT_DETAIL_DEBT",
  UPDATE_DEBT: "UPDATE_DEBT",
  DELETE_DEBT_RESIDENT: "DELETE_DEBT_RESIDENT",
  SHOW_PARENTS: "SHOW_PARENTS",
  ADD_PARENTS: "ADD_PARENTS",
  UPDATE_PARENTS: "UPDATE_PARENTS",
  DELETE_PARENTS: "DELETE_PARENTS",
  SHOW_DEBT_REPORT: "SHOW_DEBT_REPORT",
  SHOW_FILTER_RESIDENT: "SHOW_FILTER_RESIDENT",
  SHOW_ADVANCES: "SHOW_ADVANCES",
  ADD_ADVANCES: "ADD_ADVANCES",
  UPDATE_ADVANCES: "UPDATE_ADVANCES",
  DELETE_ADVANCES: "DELETE_ADVANCES",
  ADD_PAYMENT_DEBT_RESIDENT: "ADD_PAYMENT_DEBT_RESIDENT",
  PULL_ADVANCES: "PULL_ADVANCES",
  SHOW_BUILDING_RESIDENTS: "SHOW_BUILDING_RESINDENTS",
  CHECK_LICENCE:'CHECK_LICENCE',
  ADD_EXCEL_RESIDENT:'ADD_EXCEL_RESIDENT'
};
