import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],
  smsPreview: {},
  is_filter_load:false
};
const ToGroupSmsSlice = createSlice({
  name: "toGroupSms",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showGroups: (state, action) => {
      state.groups = action.payload;
    },
    showSmsPreview: (state, action) => {
      state.smsPreview = action.payload;
    },
  },
});
export const { showGroups, showSmsPreview,filterLoad } = ToGroupSmsSlice.actions;
export default ToGroupSmsSlice.reducer;
