import axios from "axios";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { PollSliceTypes } from "./types";
import toast from "react-hot-toast";
import {
  showPoll, showPollDetails
} from "./slice";
import { application } from "../../../../../redux/store";

function* showPollHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/surveys?page=${page}`)
    );
    yield put(showPoll(response.data.data.Records));
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showPollDetailsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/survey/${payload.id}`)
    );
    yield put(showPollDetails(response.data.data));
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addPollHandler({ payload, page}) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/survey`, {
        end_date: payload.end_date,
        options: payload.options,
        question: payload.question,
        title: payload.title
      })
    );
    toast.success(response.data.description);
    yield showPollHandler(page)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updatePollHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/survey`, {
        id: payload.id,
        end_date: payload.end_date,
        options: payload.options,
        question: payload.question,
        title: payload.title
      })
    );
    toast.success(response.data.description);
    yield showPollHandler(page)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deletePollHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/survey`, {
        data: {
          id: payload.id,
        },
      })
    );
    toast.success(response.data.description);
    yield showPollHandler(payload.page)
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

export function* PollSagas() {
  yield all([
    takeEvery(PollSliceTypes.SHOW_POLL, showPollHandler),
    takeEvery(PollSliceTypes.SHOW_POLL_DETAILS, showPollDetailsHandler),
    takeEvery(PollSliceTypes.ADD_POLL, addPollHandler),
    takeEvery(PollSliceTypes.UPDATE_POLL, updatePollHandler),
    takeEvery(PollSliceTypes.DELETE_POLL, deletePollHandler),
  ]);
}
