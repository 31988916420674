import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../../redux/store";
import { showResidents, showSmsPreview, showSmsTemplates,filterLoad } from "./slice";
import { ToResidentSmsTypes } from "./types";

function* showResidentSmsHandler({ payload, page }) {
  const response = yield call(() =>
    axios.post(`${application.api}/resident/all?page=${page}`, payload)
  );
  yield put(showResidents(response.data.data.Records));
  yield put(filterLoad(false))
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* sendSmsPreviewHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident/sms/preview`, {
        all_data: payload?.all_data,
        future_date: {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          start_date: payload?.date,
        },
        is_future_date: payload?.is_future_date,
        message: payload?.message,
        name: payload?.name,
        national_id: payload?.national_id,
        not_sending: payload?.not_sending,
        phone: payload?.phone,
        sending: payload?.sending,
        sms_template_id: payload?.sms_template_id,
      })
    );
    yield put(showSmsPreview(response.data.data));
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* sendSmsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/sms/send`, {
        all_data: payload?.all_data,
        future_date: {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          start_date: payload?.date,
        },
        is_future_date: payload?.is_future_date,
        message: payload?.message,
        name: payload?.name,
        national_id: payload?.national_id,
        not_sending: payload?.not_sending,
        phone: payload?.phone,
        sending: payload?.sending,
        sms_template_id: payload?.sms_template_id,
      })
    );
    if (payload.continually) {
      yield call(() =>
        axios.post(`${application.api}/sms/jobs`, {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          job_type: 0,
          last_date: payload.continually_date,
          message: payload.message,
          name: "test",
          resident_ids: payload.sending,
        })
      );
    }
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
export function* ToResidentSmsSagas() {
  yield all([
    takeEvery(ToResidentSmsTypes.SHOW_SMS_RESIDENT, showResidentSmsHandler),
    takeEvery(ToResidentSmsTypes.SHOW_SMS_RESIDENT, filterLoadHandler),
    takeEvery(
      ToResidentSmsTypes.SEND_SMS_RESIDENT_PREVIEW,
      sendSmsPreviewHandler
    ),
    takeEvery(ToResidentSmsTypes.SEND_SMS_RESIDENT, sendSmsHandler),
  ]);
}
