import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  apartments: [],
  sms_code: false,
  apartmentDetails: [],
  apartmentOwns: [],
  apartmentTenants: [],
  apartmentCollections: [],
  apartmentDebts: [],
  allApartmentDebts: [],
  apartmentDebtsDetail: [],
  stockHolders: [],
  filterApartments: [],
  apartmentDebtGroups: [],
  excelApartmentError: [],
  is_filter_load: false,
  is_posted_user: false,
}
const ApartmentSlice = createSlice({
  name: 'ApartmentSlice',
  initialState: initialState,
  reducers: {
    handleUserPost: (state, action) => {
      state.is_posted_user = action.payload
    },
    filterLoad: (state, action) => {
      state.is_filter_load = action.payload
    },
    showApartment: (state, action) => {
      state.apartments = action.payload
    },
    filterApartments: (state, action) => {
      state.filterApartments = action.payload
    },
    addApartment: (state, action) => {
      state.apartments.push(action.payload)
    },
    deleteApartment: (state, action) => {
      state.apartments = state.apartments.filter(
        (el) => el.id !== action.payload
      )
    },
    uppdateApartment: (state, action) => {
      //TODOS BURAYA AOPRTMAN GÜNCELLENMESİ YAPILACAK
      // state.apartments.
    },
    showApartmentDebts: (state, action) => {
      state.apartmentDebts = action.payload
    },
    showAllApartmentDebts: (state, action) => {
      state.allApartmentDebts = action.payload
    },
    deleteApartmentDebts: (state, action) => {
      state.apartmentDebts.filter((el) => el.id !== action.payload)
    },
    showApartmentDebtsDetail: (state, action) => {
      state.apartmentDebtsDetail = action.payload
    },
    showApartmentCollections: (state, action) => {
      state.apartmentCollections = action.payload
    },
    showApartmentDebtGroups: (state, action) => {
      state.apartmentDebtGroups = action.payload
    },
    showApartmentDetail: (state, action) => {
      state.apartmentDetails = action.payload
    },
    showApartmentOwn: (state, action) => {
      state.apartmentOwns = action.payload
    },
    deleteApartmentOwn: (state, action) => {
      state.apartmentOwns.filter((el) => el.id !== action.payload)
    },
    showApartmentTenant: (state, action) => {
      state.apartmentTenants = action.payload
    },
    deleteApartmentTenant: (state, action) => {
      state.apartmentTenants.filter((el) => el.id !== action.payload)
    },
    showStockHolder: (state, action) => {
      state.stockHolders = action.payload
    },
    addStockHolder: (state, action) => {
      state.stockHolders.push(action.payload)
    },
    updateStockHolder: (state, action) => {
      const index = state.stockHolders.findIndex(
        (el) => el.id === action.payload.id
      )
      state.stockHolders[index] = {
        ...state.stockHolders[index],
        percent: parseInt(action.payload.percent),
      }
      state.showApartmentOwn = state.stockHolders
    },
    deleteStockHolder: (state, action) => {
      state.stockHolders.filter((el) => el.id !== action.payload)
    },
    updateSmsCode: (state, action) => {
      state.sms_code = action.payload
    },
    addExcelApartmentError: (state, action) => {
      state.excelApartmentError = action.payload
    },
  },
})
export const {
  handleUserPost,
  filterLoad,
  addApartment,
  showApartment,
  deleteApartment,
  showApartmentDetail,
  showApartmentOwn,
  deleteApartmentOwn,
  showApartmentTenant,
  deleteApartmentTenant,
  showApartmentDebts,
  showAllApartmentDebts,
  showApartmentCollections,
  showApartmentDebtsDetail,
  deleteApartmentDebts,
  showStockHolder,
  addStockHolder,
  updateStockHolder,
  filterApartments,
  showApartmentDebtGroups,
  updateSmsCode,
  addExcelApartment,
  addExcelApartmentError,
} = ApartmentSlice.actions
export default ApartmentSlice.reducer
