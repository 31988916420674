import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  apartments: [],
  accounting_type_id: [],
  exemptionIdByApartment: [],
  is_filter_load: false,
}
const DebtExemptionSlice = createSlice({
  name: 'apartments',
  initialState: initialState,
  reducers: {
    filterLoad: (state, action) => {
      state.is_filter_load = action.payload
    },
    showApartmentByBuilding: (state, action) => {
      state.apartments = action.payload
    },
    sendDebtExemption: (state, action) => {
      state.accounting_type_id.push(action.payload.accounting_type_id)
    },
    showExemptionByApartment: (state, action) => {
      state.exemptionIdByApartment = action.payload
    },
    addExemptionByApartment: (state, action) => {
      // state.exemptionIdByApartment = action.payload;
    },
    deleteExemptionByApartment: (state, action) => {
      state.exemptionIdByApartment.filter(
        (el) => el.accounting_type_id !== action.payload
      )
    },
  },
})
export const {
  filterLoad,
  showApartmentByBuilding,
  sendDebtExemption,
  showExemptionByApartment,
  deleteExemptionByApartment,
  addExemptionByApartment,
} = DebtExemptionSlice.actions

export default DebtExemptionSlice.reducer
