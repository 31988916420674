import axios from "axios";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { AccountPaymentTypes } from "./types";
import toast from "react-hot-toast";
import {
  addPayment,
  deletePayment,
  showPayment,
  updatePayment,
} from "./slice";

function* showAccountingHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/payments?page=${page}&type=1`)
    );
    yield put(showPayment(response.data.data.Records));
  } catch (error) { }
}

function* newAccountingHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/payment`, {
        amount: payload.data.amount,
        account_id: payload.data.account_id,
        date: payload.data.date,
        description: payload.data.description,
        id: payload.data.id,
        accounting_type_id: payload.data.accounting_type_id,
      })
    );
    yield put(addPayment(payload));
    yield showAccountingHandler({ page: 1 });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* updateAccountingHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/payment`, {
        amount: payload.amount,
        account_id: payload.account_id,
        date: payload.date,
        description: payload.description,
        id: payload.id,
        accounting_type_id: payload.accounting_type_id,
      })
    );
    yield put(updatePayment(payload));
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* deleteAccountingHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/payment`, {
        data: {
          id: payload,
        },
      })
    );
    yield put(deletePayment(payload));
    yield showAccountingHandler(page);
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}


export function* AccountPaymentSagas() {
  yield all([
    takeEvery(AccountPaymentTypes.SHOW_PAYMENT, showAccountingHandler),
    takeEvery(AccountPaymentTypes.ADD_PAYMENT, newAccountingHandler),
    takeEvery(AccountPaymentTypes.DELETE_PAYMENT, deleteAccountingHandler),
    takeEvery(AccountPaymentTypes.UPDATE_PAYMENT, updateAccountingHandler),
  ]);
}
