import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  conversations: [],
  detail: [],
}

const ConversationSlice = createSlice({
  name: 'ConversationSlice',
  initialState,
  reducers: {
    sendConversationDetail: (state, action) => {
      state.detail = action.payload
    },
    showConversation: (state, action) => {
      state.conversations = action.payload
    },
    sendConversation: (state, action) => {
      state.detail = [...state.detail, action.paylaod]
    },
    deleteConversation: (state, action) => {
      state.ticketDetails = state.ticketDetails.filter(
        (el) => el.id !== action.payload
      )
    },
  },
})

export const {
  sendConversationDetail,
  showConversation,
  deleteConversation,
  sendConversation,
} = ConversationSlice.actions

export default ConversationSlice.reducer
