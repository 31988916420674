import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentAccounts: [],
}
const CurrentAccountSlice = createSlice({
  name: 'currentAccounts',
  initialState: initialState,
  reducers: {
    showCurrentAccount: (state, action) => {
      state.currentAccounts = action.payload
    },
    addCurrentAccount: (state, action) => {
      state.currentAccounts.push(action.payload)
    },
    updateCurrentAccount: (state, action) => {
      const updateIndex = state.currentAccounts.findIndex(
        (el) => el.id === action.payload.id
      )
      state.currentAccounts[updateIndex] = action.payload
    },
    deleteCurrentAccount: (state, action) => {
      state.currentAccounts = state.currentAccounts.filter(
        (el) => el.id !== action.payload
      )
    },
  },
})
export const {
  showCurrentAccount,
  addCurrentAccount,
  updateCurrentAccount,
  deleteCurrentAccount,
} = CurrentAccountSlice.actions
export default CurrentAccountSlice.reducer
