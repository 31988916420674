import axios from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { numberReplace } from '../../../../../functions/Number/numberReplace'
import toast from 'react-hot-toast'
import { ForgotPasswordTypes } from './types'
import { isRepeat, isSent, isValid } from './slice'

function* sendForgotPasswordCode({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/lost/password`, {
        phone: numberReplace(payload.phone),
      })
    )
    toast.success(response.data.description)
    yield put(isValid(true))
    yield put(isRepeat(true))
  } catch (error) {
    toast.error(error.response.data.description)
    yield put(isValid(false))
  }
}

function* renewAccountPassword({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/renew/password`, {
        // phone: numberReplace(payload.phone),
        phone: payload.phone.replaceAll(' ', ''),
        new_password: payload.new_password,
        code: payload.code,
      })
    )
    toast.success('Şifre başarıyla değiştirildi')
    yield put(isSent(true))
  } catch (error) {
    toast.error(error.response.data.description)
    yield put(isSent(false))
  }
}

function* resetAllSteps() {
  try {
    yield put(isSent(false))
    yield put(isValid(false))
  } catch (error) {}
}

function* repeatCodeHandler() {
  try {
    yield put(isRepeat(false))
  } catch (error) {}
}

export function* ForgotPasswordSagas() {
  yield all([
    takeEvery(
      ForgotPasswordTypes.SEND_FORGOT_PASSWORD_CODE,
      sendForgotPasswordCode
    ),
    takeEvery(ForgotPasswordTypes.REPEAT_CODE, repeatCodeHandler),
    takeEvery(ForgotPasswordTypes.RENEW_ACCOUNT_PASSWORD, renewAccountPassword),
    takeEvery(ForgotPasswordTypes.RESET_ALL_PASSWORD_STEPS, resetAllSteps),
  ])
}
