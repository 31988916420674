import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  residents: [],
  residentsSingle: {},
  parents: [],
  residentDebts: [],
  advances: [],
  advancesSum: 0,
  receipt: {
    id: '',
    remainingAmount: '',
    logo: '',
    name: '',
    surname: '',
    barcode: '',
    amount: '',
  },
  isDelete: null,
  resident_id: 0,
  is_filter_load: false,
  is_wp: false,
  is_posted_resident: false,
  excelResidentError: [],
}
const ResidentSlice = createSlice({
  name: 'residentsSlice',
  initialState: initialState,
  reducers: {
    handleResidentPost: (state, action) => {
      state.is_posted_resident = action.payload
    },
    showResident: (state, action) => {
      state.residents = action.payload
    },
    filterResident: (state, action) => {
      state.residents = action.payload
    },
    filterLoad: (state, action) => {
      state.is_filter_load = action.payload
    },
    addResident: (state, action) => {
      state.resident_id = action.payload
    },
    detailResident: (state, action) => {
      state.residentsSingle = action.payload
    },
    updateResident: (state, action) => {
      const updateIndex = state.residents.findIndex(
        (el) => el.id === action.payload.id
      )
      state.residents[updateIndex] = action.payload
      state.residentsSingle = action.payload
    },
    deleteResident: (state, action) => {
      const newResidents = state.residents.filter(
        (el) => el.id !== action.payload.id
      )
      state.residents = newResidents
    },
    showParents: (state, action) => {
      state.parents = action.payload
    },
    addParents: (state, action) => {
      state.parents.push(action.payload)
    },
    deleteParents: (state, action) => {
      const parents = state.parents.filter((el) => el.id !== action.id)
      state.parents = parents
    },
    updateParents: (state, action) => {
      const updateIndex = state.parents.findIndex(
        (el) => el.id === action.payload.id
      )
      state.parents[updateIndex] = action.payload
    },
    showDebtReport: (state, action) => {
      state.residentDebts = action.payload
    },
    showAdvances: (state, action) => {
      state.advances = action.payload.Records
      state.advancesSum = action.payload.AdditionalData.sum
    },
    receiptData: (state, action) => {
      state.receipt = action.payload
    },
    isDeleteAction: (state, action) => {
      state.isDelete = action.payload
    },
    checkLicence: (state, action) => {
      state.is_wp = action.payload
    },
    addExcelResidentError: (state, action) => {
      state.excelResidentError = action.payload
    },
  },
})
export const {
  handleResidentPost,
  addResident,
  detailResident,
  updateResident,
  deleteResident,
  showResident,
  showParents,
  addParents,
  showDebtReport,
  filterResident,
  filterLoad,
  updateParents,
  deleteParents,
  showAdvances,
  showAdvancesSum,
  receiptData,
  isDeleteAction,
  checkLicence,
  addExcelResidentError,
} = ResidentSlice.actions
export default ResidentSlice.reducer
