import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: [],
  tickets: [],
  ticketDetails: [],
  ticket_error: false,
}

const TicketSlice = createSlice({
  name: 'TicketSlice',
  initialState,
  reducers: {
    sendTickes: (state, action) => {
      state.messages = action.payload
    },
    errorTickes: (state, action) => {
      state.ticket_error = action.payload
    },
    showTickets: (state, action) => {
      state.tickets = action.payload
    },
    showTicketDetail: (state, action) => {
      state.ticketDetails = action.payload
    },
  },
})

export const { sendTickes, errorTickes, showTickets, showTicketDetail } =
  TicketSlice.actions

export default TicketSlice.reducer
