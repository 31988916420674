import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import {
  showSurvey,
  showSmsPreview,
  isSubmitSms,
  showBuildings,
  filterLoad
} from "./slice";
import { PollSmsTypes } from "./types";

function* showSurveyResidentHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/building?page=${page}`, {
        params: {
          key: payload.key,
        },
      })
    );
    yield put(showBuildings(response.data.data.Records));
    yield put(filterLoad(false))
} catch (error) {
    yield put(showBuildings([]));
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* showSurveyHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/surveys?page=${page}`)
    );
    const filterResponse = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.title,
    }));
    yield put(showSurvey(filterResponse));
  } catch (error) {
    yield put(showSurvey([]));
  }
}

function* sendSmsPreviewHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/survey/preview`, {
        all_data: payload?.all_data,
        future_date: {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          start_date: payload?.date,
        },
        is_future_date: payload?.is_future_date,
        message: payload?.message,
        name: payload?.name,
        survey_id: payload?.survey,
        not_sending: payload?.not_sending,
        phone: payload?.phone,
        building_ids: payload?.sending,
      })
    );
    yield put(showSmsPreview(response.data.data));
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* sendSmsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/survey/send`, {
        all_data: payload?.all_data,
        future_date: {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          start_date: payload?.date,
        },
        is_future_date: payload?.is_future_date,
        message: payload?.message,
        name: payload?.name,
        survey_id: payload?.survey,
        not_sending: payload?.not_sending,
        phone: payload?.phone,
        building_ids: payload?.sending,
      })
    );
    if (payload.continually) {
      yield call(() =>
        axios.post(`${application.api}/sms/jobs`, {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          job_type: 0,
          last_date: payload.continually_date,
          message: payload.message,
          name: "test",
          resident_ids: payload.sending,
        })
      );
    }
    toast.success(response.data.description);
    yield put(isSubmitSms(true));
  } catch (error) {
    toast.error(error.response.data.description);
    yield put(isSubmitSms(false));
  }
}
export function* PollSmsSagas() {
  yield all([
    takeEvery(PollSmsTypes.SHOW_SURVEY_SMS, showSurveyHandler),
    takeEvery(PollSmsTypes.SHOW_SURVEY_RESIDENT, showSurveyResidentHandler),
    takeEvery(PollSmsTypes.SHOW_SURVEY_RESIDENT, filterLoadHandler),
    takeEvery(PollSmsTypes.SEND_SMS_PREVIEW, sendSmsPreviewHandler),
    takeEvery(PollSmsTypes.SEND_SMS_DEBT, sendSmsHandler),
  ]);
}
