import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { ApartmentGroupDebtTypes } from './types'
import {
  showApartment,
  addGroupDebt,
  showDebtGroups,
  filterLoad,
} from './slice'
import toast from 'react-hot-toast'
function* showGroupDebtHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartments/only/resident`, {
        buildings: payload.id,
      })
    )
    yield put(showApartment(response.data.data))
    yield put(filterLoad(false))
  } catch (error) {
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* addGroupDebtHandler({ payload }) {
  payload.groups = payload.groups?.map((item) => ({
    id: item.id,
    amount: parseFloat(item.amount),
    name: item.name,
  }))

  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/groups`, payload)
    )
    toast.success(response.data.description)
    yield put(addGroupDebt(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* showDebtGroupHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/groups?&type=1`)
    )
    yield put(showDebtGroups(response.data.data.Records))
  } catch (error) {
  }
}
export function* GroupDebtSagas() {
  yield all([
    takeEvery(
      ApartmentGroupDebtTypes.SHOW_APARTMENT_GROUP_DEBT,
      showGroupDebtHandler
    ),
    takeEvery(ApartmentGroupDebtTypes.ADD_GROUP_DEBT, addGroupDebtHandler),
    takeEvery(ApartmentGroupDebtTypes.SHOW_DEBT_GROUPP, showDebtGroupHandler),
    takeEvery(ApartmentGroupDebtTypes.SHOW_DEBT_GROUPP, filterLoadHandler),
  ])
}
