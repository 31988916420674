import axios from "axios";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { AccountExpenseTypes } from "./types";
import toast from "react-hot-toast";
import { addExpense, deleteExpense, handlePost, showExpense, updateExpense } from "./slice";

function* showExpenseHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/expenses?page=${page}&type=2`)
    );
    yield put(showExpense(response.data.data.Records));
  } catch (error) {}
}

function* newExpenseHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/expense`, {
        amount: payload.data.amount,
        account_id: payload.data.account_id,
        date: payload.data.date,
        bill_date: payload.data.bill_date,
        description: payload.data.description,
        id: payload.data.id,
        accounting_type_id: payload.data.accounting_type_id,
      })
    );
    yield put(addExpense(response.data.data));
    yield showExpenseHandler(payload);
    yield put(handlePost(true))
    toast.success(response.data.description);
  } catch (error) {
    yield put(handlePost(false))
    toast.error(error.response.data.description);
  }
}

function* updateExpenseHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/expense`, {
        amount: payload.amount,
        account_id: payload.account_id,
        date: payload.date,
        bill_date: payload.bill_date,
        description: payload.description,
        id: payload.id,
        accounting_type_id: payload.accounting_type_id,
      })
    );
    yield put(updateExpense(payload));
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* deleteExpenseHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/expense`, {
        data: {
          id: payload.data,
        },
      })
    );
    yield put(deleteExpense(payload));
    yield showExpenseHandler(payload);
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

export function* AccountExpenseSagas() {
  yield all([
    takeEvery(AccountExpenseTypes.SHOW_EXPENSE, showExpenseHandler),
    takeEvery(AccountExpenseTypes.ADD_EXPENSE, newExpenseHandler),
    takeEvery(AccountExpenseTypes.DELETE_EXPENSE, deleteExpenseHandler),
    takeEvery(AccountExpenseTypes.UPDATE_EXPENSE, updateExpenseHandler),
  ]);
}
