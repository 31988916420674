import axios from "axios";
import toast from "react-hot-toast";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { addSmsJobs, deleteSmsJobs, showSmsJobs, updateSmsJobs } from "./slice";
import { SmsJobsTypes } from "./types";

function* showSmsJobsHandler({ page }) {
  const response = yield call(() =>
    axios.get(`${application.api}/sms/jobs?page=${page}`)
  );
  yield put(showSmsJobs(response.data.data.Records));
}
function* addSmsJobsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/sms/jobs`, {
        name: payload.name,
        last_date: payload.last_date,
        hour: parseInt(payload.hour),
        minute: parseInt(payload.minute),
        message: payload.message,
        job_type: payload.job_type.id,
        resident_ids: payload.resident_ids.map((item) => item.id),
      })
    );
    yield put(addSmsJobs(response.data.data));
    yield showSmsJobsHandler({ page: 1 });
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* updateSmsJobsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/sms/jobs`, {
        name: payload.name,
        last_date: payload.last_date,
        hour: parseInt(payload.hour),
        minute: parseInt(payload.minute),
        message: payload.message,
        job_type: payload.job_type.id,
        resident_ids: payload.resident_ids.map((item) => item.id),
        id: payload.id,
      })
    );
    yield showSmsJobsHandler({ page: 1 });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* deleteSmsJobsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/sms/jobs`, {
        data: {
          id: payload.id,
        },
      })
    );
    yield put(deleteSmsJobs(payload));
    yield showSmsJobsHandler({ page: payload.page });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

export function* SmsJobsSagas() {
  yield all([
    takeEvery(SmsJobsTypes.SHOW_SMS_JOBS, showSmsJobsHandler),
    takeEvery(SmsJobsTypes.ADD_SMS_JOBS, addSmsJobsHandler),
    takeEvery(SmsJobsTypes.DELETE_SMS_JOBS, deleteSmsJobsHandler),
    takeEvery(SmsJobsTypes.UPDATE_SMS_JOBS, updateSmsJobsHandler),
  ]);
}
