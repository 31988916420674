import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apartmentsPersonDebt: [],
  debts: [],
  debts_error: [],
  is_filter_load:false
};
const PersonDebtSlice = createSlice({
  name: "PersonDebtSlice",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showApartment: (state, action) => {
      state.apartmentsPersonDebt = action.payload;
    },
    addPersonDebt: (state, action) => {
      state.debts_error = action.payload.debts_error
    },
  },
});
export const { showApartment, addPersonDebt, filterLoad } = PersonDebtSlice.actions;
export default PersonDebtSlice.reducer;
