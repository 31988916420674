import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { showAccountActivities, showAccActivitiesTotal, filterLoad } from "./slice";
import { AccountActivitiesTypes } from "./types";

function* getAccountActivities({ payload }) {
    try {
    const response = yield call(() =>
        axios.post(`${application.api}/pay/exp/report?page=${payload.page}`, {
            accountID: payload.data?.accountID,
            start_date: payload.data?.start_date,
            end_date: payload.data?.end_date,
        })
    );
    yield put(showAccountActivities(response.data.data.Records));
    yield put(showAccActivitiesTotal(response.data.data.AdditionalData));
    yield put(filterLoad(false))
} catch (error) {
    toast.error(error.response.data.description);
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

export function* AccountActivitiesSagas() {
  yield all([
    takeLatest(AccountActivitiesTypes.GET_ACC_ACTIVITIES, getAccountActivities),
    takeLatest(AccountActivitiesTypes.GET_ACC_ACTIVITIES, filterLoadHandler)
  ]);
}
