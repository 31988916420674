import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    collections: [],
    is_true:false,
    is_filter_load:false
}
const AccountCollectionSlice = createSlice({
    name: "collection",
    initialState: initialState,
    reducers: {
        filterLoad:(state,action)=>{
            state.is_filter_load = action.payload
        },
        showCollection: (state, action) => {
            state.collections = action.payload
        },
        showPdfCollections:(state,action)=>{
            state.is_true = action.payload
        }
    }
})
export const { showCollection ,showPdfCollections,filterLoad} = AccountCollectionSlice.actions

export default AccountCollectionSlice.reducer