import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    poll: [],
    isVoteSent: false,
}
const VoteSlice = createSlice({
    name: "voteSlice",
    initialState: initialState,
    reducers: {
        showPollById: (state, action) => {
            state.poll = action.payload
        },
        isVoteSent: (state, action) => {
            state.isVoteSent = action.payload
        },
    }
})
export const { showPollById, isVoteSent } = VoteSlice.actions

export default VoteSlice.reducer