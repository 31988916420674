import axios from "axios";
import toast from "react-hot-toast";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { showVirman } from "./slice";
import { VirmanTypes } from "./types";

function* showVirmanHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/virman?page=${payload.page}`)
    );
    yield put(showVirman(response.data.data.Records));
  } catch (error) {}
}
function* addVirmanHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/virman`, {
        amount: payload.amount,
        date: payload.date,
        description: payload.description,
        from_account_id: payload.output_account_id,
        to_account_id: payload.input_account_id,
      })
    );
    yield showVirmanHandler({ payload: { page: 1 } });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* deleteVirmanHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/virman`, {
        data: {
          id: payload,
        },
      })
    );
    yield showVirmanHandler({ payload: { page: 1 } });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

export function* VirmanSagas() {
  yield all([
    takeEvery(VirmanTypes.SHOW_VIRMAN, showVirmanHandler),
    takeEvery(VirmanTypes.ADD_VIRMAN, addVirmanHandler),
    takeEvery(VirmanTypes.DELETE_VIRMAN, deleteVirmanHandler),
  ]);
}
