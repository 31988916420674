export const UserSearchTypes = {
  GET_USER: 'GET_USER',
  SHOW_USERS: 'SHOW_USERS',
  SEARCH_USERS_KEY: 'SEARCH_USERS_KEY',
  GET_TUTORIAL: 'GET_TUTORIAL',
  UPDATE_TUTORIAL: 'UPDATE_TUTORIAL',
  IS_FIRST_TUTORIAL: 'IS_FIRST_TUTORIAL',
  FINISHED_TUTORIAL: 'FINISHED_TUTORIAL',
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
  USER_PAYMENT: 'USER_PAYMENT',
}
