import axios from 'axios'
import toast from 'react-hot-toast'
import { application } from '../../../../../../redux/store'
import { takeLatest, all, call, put } from 'redux-saga/effects'
import { filterLoad, showTransferRecords } from './slice'
import { TransferRecordsTypes } from './types'

function* getTransferRecords({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/virman`, {
        params: {
          page: payload.page,
          start_date: payload.data?.start_date,
          end_date: payload.data?.end_date,
        },
      })
    )
    yield put(showTransferRecords(response.data.data.Records))
    yield put(filterLoad(false))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

export function* TransferRecordsSagas() {
  yield all([
    takeLatest(TransferRecordsTypes.GET_TRANSFER_RECORDS, getTransferRecords),
    takeLatest(TransferRecordsTypes.GET_TRANSFER_RECORDS, filterLoadHandler),
  ])
}
