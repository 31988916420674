import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

const initialState = {
    balanceReport:{},
}

const BalanceReportSlice = createSlice({
    name:"balanceReportSlice",
    initialState:initialState,
    reducers: {
        showBalanceReport: (state, action) => {
            // action.payload.map((item) => (
            //     item.start_date = moment(item.date).format("DD.MM.YYYY"),
            //     item.end_date = moment(item.date).format("DD.MM.YYYY")
            // ))
            state.balanceReport = action.payload
        },
    }
})

export const { showBalanceReport } = BalanceReportSlice.actions

export default BalanceReportSlice.reducer