import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

const initialState = {
    businessLedger:{},
    businessLedgerTotal:{},
    is_filter_load:false
}

const BusinessLedgerSlice = createSlice({
    name:"businessLedgerSlice",
    initialState:initialState,
    reducers: {
        filterLoad:(state,action)=>{
            state.is_filter_load = action.payload
        },
        showBusinessLedger: (state, action) => {
            action.payload.map((item) => (
                item.start_date = moment(item.date).format("DD.MM.YYYY"),
                item.end_date = moment(item.date).format("DD.MM.YYYY")
            ))
            state.businessLedger = action.payload
        },
        showBusinessLedgerTotal: (state, action) => {
            state.businessLedgerTotal = action.payload
        }
        
    }
})

export const { showBusinessLedger, showBusinessLedgerTotal ,filterLoad} = BusinessLedgerSlice.actions

export default BusinessLedgerSlice.reducer