import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isCodeSent: false,
  isPhoneValid: false,
  isRepeatCodeSend: false,
}
const ForgotPasswordSlice = createSlice({
  name: 'ForgotPasswordSlice',
  initialState: initialState,
  reducers: {
    isSent: (state, action) => {
      state.isCodeSent = action.payload
    },
    isValid: (state, action) => {
      state.isPhoneValid = action.payload
    },
    isRepeat: (state, action) => {
      state.isRepeatCodeSend = action.payload
    },
  },
})
export const { isSent, isValid, isRepeat } = ForgotPasswordSlice.actions
export default ForgotPasswordSlice.reducer
