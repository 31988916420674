import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  residents: [],
  tutorial: {},
  notifications: [],
  payments: [],
}

const UserSearchSlice = createSlice({
  name: 'UserSearchSlice',
  initialState: initialState,
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload
    },
    showResidents: (state, action) => {
      state.residents = action.payload
    },
    getTutorial: (state, action) => {
      state.tutorial = action.payload
    },
    deleteResidentPayment: (state, action) => {
      state.payments = state.payments.filter((el) => el.id !== action.payload)
    },
    getNotification: (state, action) => {
      if (action.payload.length > 0) {
        state.notifications = [...state.notifications, ...action.payload]
      }
    },
    getPayments: (state, action) => {
      state.payments = action.payload
    },
    getScrollPayments: (state, action) => {
      if (action.payload.length > 0) {
        state.payments = [...state.payments, ...action.payload]
      }
    },
  },
})

export const {
  getUser,
  showResidents,
  getTutorial,
  getNotification,
  getPayments,
  deleteResidentPayment,
  getScrollPayments,
} = UserSearchSlice.actions

export default UserSearchSlice.reducer
