import axios from 'axios'
import { takeEvery, call, all, put } from 'redux-saga/effects'
import { UserSavesTypes } from './types'
import toast from 'react-hot-toast'
import { application } from '../../../../../redux/store'
import { deleteConversation, sendConversationDetail, showConversation } from './slice'

function* getConversationHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/user/messages?page=${payload.page}`)
    )
    yield put(showConversation(response.data.data.Records))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* getDetailConversationHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/user/messages/${payload.id}`)
    )
    yield put(sendConversationDetail(response.data.data.Records))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* sendConversationMessageHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(
        `${application.api}/user/sendmessage/${payload.residentID}`,
        payload
      )
    )
    yield getDetailConversationHandler({ payload: { id: payload.residentID } })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteConversationHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/user/message/${payload.id}`)
    )
    yield put(deleteConversation(payload.id))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* ConversationSagas() {
  yield all([
    takeEvery(UserSavesTypes.SHOW_CONVERSATION, getConversationHandler),
    takeEvery(UserSavesTypes.DETAIL_CONVERSATION, getDetailConversationHandler),
    takeEvery(UserSavesTypes.POST_MESSAGE, sendConversationMessageHandler),
    takeEvery(UserSavesTypes.DELETE_CONVERSATION, deleteConversationHandler),
  ])
}
