import axios from 'axios'
import { put, all, takeEvery, call } from 'redux-saga/effects'
import { application } from '../store'
import { showBuilding, showGroup } from './slice'
import { FilterTypes } from './types'

function* showBuildingHandler() {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/buildings`,{all:true})
    )
    yield put(showBuilding(response.data.data))
  } catch (error) {}
}
function* showGroupsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/groups/all?type=${payload.type}`)
    )
    yield put(showGroup(response.data.data))
  } catch (error) {}
}

export function* rootFilterSagas() {
  yield all([
    takeEvery(FilterTypes.SHOW_FILTER_BUILDING, showBuildingHandler),
    takeEvery(FilterTypes.SHOW_FILTER_GROUPS, showGroupsHandler),
  ])
}
