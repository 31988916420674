import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  receipt: [],
}
const ReceiptSlice = createSlice({
  name: 'ReceiptSlice',
  initialState: initialState,
  reducers: {
    getReceiptData: (state, action) => {
      state.receipt = action.payload
    },
  },
})
export const { getReceiptData } = ReceiptSlice.actions
export default ReceiptSlice.reducer
