import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apartmentsSingleDebt: [],
  debts: [],
  smsPreview: {},
  is_filter_load:false
};
const toDebtSmsSlice = createSlice({
  name: "toDebtSms",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showDebts: (state, action) => {
      state.debts = action.payload;
    },
    showSmsPreview: (state, action) => {
      state.smsPreview = action.payload;
    },
  },
});
export const { showDebts, showSmsPreview, filterLoad} = toDebtSmsSlice.actions;
export default toDebtSmsSlice.reducer;
