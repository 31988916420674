import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logs: [],
};

const LogsSlice = createSlice({
  name: "LogsSlice",
  initialState,
  reducers: {
    showLogs: (state, action) => {
      state.logs = action.payload;
    },
  },
});

export const { showLogs } = LogsSlice.actions;

export default LogsSlice.reducer;
