import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { VoteTypes } from './types'
import toast from 'react-hot-toast'
import { showPollById, isVoteSent } from './slice'
import { application } from '../../../../redux/store'

function* showPollHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/survey/${payload.survey_id}`)
    )
    yield put(showPollById(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* sendVoteHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/vote/survey`, {
        resident_id: parseInt(payload.resident_id),
        survey_id: parseInt(payload.survey_id),
        vote: payload.vote,
      })
    )
    yield put(isVoteSent(true))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* VoteSagas() {
  yield all([
    takeEvery(VoteTypes.SHOW_POLL_BY_ID, showPollHandler),
    takeEvery(VoteTypes.SEND_VOTE, sendVoteHandler),
  ])
}
