import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  license: [],
  receipts: [],
  download_receipt: [],
}
const LicenseSlice = createSlice({
  name: 'license',
  initialState: initialState,
  reducers: {
    getLicense: (state, action) => {
      state.license = action.payload
    },
    getReceipts: (state, action) => {
      state.receipts = action.payload
    },
    downloadReceipt: (state, action) => {
      state.download_receipt = action.payload
    },
  },
})
export const { getLicense, getReceipts, downloadReceipt } = LicenseSlice.actions
export default LicenseSlice.reducer
