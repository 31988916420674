import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  staffs: [],
  isStaffAdded: false,
}
const DefinationSagas = createSlice({
  name: 'staffs',
  initialState: initialState,
  reducers: {
    showStaff: (state, action) => {
      state.staffs = action.payload
    },
    addStaff: (state, action) => {
      state.staffs.push(action.payload)
    },
    isStaffAdded: (state, action) => {
      state.isStaffAdded = action.payload
    },
    updateStaff: (state, action) => {
      const staffIndex = state.staffs.findIndex(
        (el) => el.id === action.payload.id
      )
      state.staffs[staffIndex] = action.payload
    },
    deleteStaff: (state, action) => {
      state.staffs = state.staffs.filter((el) => el.id !== action.payload)
    },
  },
})
export const { showStaff, addStaff, deleteStaff, updateStaff, isStaffAdded } =
  DefinationSagas.actions

export default DefinationSagas.reducer
