import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  smsNonMembers: [],
};
const SmsNonMembersSlice = createSlice({
  name: "smsNonMembers",
  initialState: initialState,
  reducers: {
    showSmsNonMembers: (state, action) => {
      state.smsNonMembers = action.payload;
    },
  },
});
export const { showSmsNonMembers } = SmsNonMembersSlice.actions;
export default SmsNonMembersSlice.reducer;
