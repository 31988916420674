import axios from 'axios'
/*eslint-disable */
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { ExcelDebtTypes } from './types'
import { addExcelDebt, debtErrors } from './slice'
import toast from 'react-hot-toast'

function* addExcelDebtHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/amount`, payload)
    )
    yield put(debtErrors(response.data.data))
    yield put(addExcelDebt(true))
    if (response.data.data.length === 0) {
      toast.success(response.data.description)
    }
  } catch (error) {
    yield put(addExcelDebt(false))
    toast.error(error.response.data.description)
  }
}
export function* ExcelDebtSagas() {
  yield all([takeEvery(ExcelDebtTypes.ADD_EXCEL_DEBT, addExcelDebtHandler)])
}
