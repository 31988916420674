import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  interests: [],
};

const InterestSlice = createSlice({
  name: "interest",
  initialState: initialState,
  reducers: {
    updateInterest: (state, action) => {
      const interestIndex = state.interests.findIndex(
        (el) => el.id === action.payload.id
      );
      state.interests[interestIndex] = action.payload;
    },
  },
});
export const { updateInterest } = InterestSlice.actions;

export default InterestSlice.reducer;