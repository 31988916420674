import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { showSmsNonMembers } from "./slice";
import { SmsNonMembersTypes } from "./types";

function* showSmsNonMembersHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/non/members?page=${page}`)
    );
    yield put(showSmsNonMembers(response.data.data.Records));
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* addSmsNonMembersHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/non/members`, payload)
    );
    yield showSmsNonMembersHandler({ page: 1 });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* deleteSmsNonMembersHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/non/members`, {
        data: {
          id: payload.id,
        },
      })
    );
    yield showSmsNonMembersHandler({ page: payload.page });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* updateSmsNonMembersHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/non/members`, payload)
    );
    yield showSmsNonMembersHandler({ page: payload.page });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
export function* SmsNonMembersSagas() {
  yield all([
    takeEvery(
      SmsNonMembersTypes.SHOW_SMS_NON_MEMBERS,
      showSmsNonMembersHandler
    ),
    takeEvery(SmsNonMembersTypes.ADD_SMS_NON_MEMBERS, addSmsNonMembersHandler),
    takeEvery(
      SmsNonMembersTypes.DELETE_SMS_NON_MEMBERS,
      deleteSmsNonMembersHandler
    ),
    takeEvery(
      SmsNonMembersTypes.UPDATE_SMS_NON_MEMBERS,
      updateSmsNonMembersHandler
    ),
  ]);
}
