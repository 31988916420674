import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branches: [],
  branches_sms: false,
  branch_login: false,
};
const BranchesSlice = createSlice({
  name: "branches",
  initialState: initialState,
  reducers: {
    loginBranches: (state, action) => {
      state.branch_login = action.payload;
    },
    showBranches: (state, action) => {
      state.branches = action.payload;
    },
    addBranches: (state, action) => {
      state.branches.push(action.payload);
    },
    deleteBranches: (state, action) => {
      state.branches = state.branches.filter(
        (el) => el.id !== action.payload.id
      );
    },
    deleteSmsBranches: (state, action) => {
      state.branches_sms = action.payload;
    },
  },
});
export const {
  showBranches,
  addBranches,
  deleteBranches,
  deleteSmsBranches,
  loginBranches,
} = BranchesSlice.actions;

export default BranchesSlice.reducer;
