import mock from "../mock";

export const searchArr = [
  {
    groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: "apartment",
        isBookmarked: localStorage.getItem('marks_1') ? true : false,
        title: "Sakin Daireler",
        icon: "Users",
        link: "/apps/sakin-islemleri",
      },
      {
        id: 2,
        target: "debit",
        isBookmarked: localStorage.getItem('marks_2') ? true : false,
        title: "Borçlandırma",
        icon: "FileText",
        link: "/apps/tekil-borc-giris",
      },
      {
        id: 3,
        target: "accounting",
        isBookmarked: localStorage.getItem('marks_3') ? true : false,
        title: "Muhasebe",
        icon: "Archive",
        link: "/apps/tahsilat",
      },
      {
        id: 4,
        target: "definitions",
        isBookmarked: localStorage.getItem('marks_4') ? true : false,
        title: "Tanımlar",
        icon: "Edit",
        link: "/apps/kasalar",
      },
      {
        id: 5,
        target: "reports",
        isBookmarked: localStorage.getItem('marks_5') ? true : false,
        title: "Raporlar",
        icon: "Book",
        link: "/apps/tum-borclar",
      },
      {
        id: 6,
        target: "additionalFeatures",
        isBookmarked: localStorage.getItem('marks_6') ? true : false,
        title: "Ek Özellikler",
        icon: "Settings",
        link: "/apps/dokumanlar",
      },
      {
        id: 7,
        target: "smsOpt",
        isBookmarked: localStorage.getItem('marks_7') ? true : false,
        title: "SMS İşlemleri",
        icon: "Mail",
        link: "/apps/toplu-sms",
      },
      {
        id: 8,
        target: "support",
        isBookmarked: localStorage.getItem('marks_8') ? true : false,
        title: "Destek",
        icon: "Info",
        link: "/apps/nasıl-kullanirim",
      },
      {
        id: 9,
        target: "settings",
        isBookmarked: localStorage.getItem('marks_9') ? true : false,
        title: "Ayarlar",
        icon: "Settings",
        link: "/apps/ayarlar",
      },
      {
        id: 10,
        target: "balance",
        isBookmarked: localStorage.getItem('marks_10') ? true : false,
        title: "Bakiye",
        icon: "CreditCard",
        link: "/apps/bakiye-islemleri",
      },
      {
        id: 11,
        target: "employee",
        isBookmarked: localStorage.getItem('marks_11') ? true : false,
        title: "Personel İşlemleri",
        icon: "Monitor",
        link: "/apps/personel-islemleri",
      },
    ],
  },
];

// GET Search Data
mock.onGet("/api/main-search/data").reply(() => {
  return [200, { searchArr }];
});

// GET Search Data & Bookmarks
mock.onGet("/api/bookmarks/data").reply(() => {
  const bookmarks = searchArr[0].data.filter((item) => item.isBookmarked);
  const suggestions = searchArr[0].data;
  return [200, { suggestions, bookmarks }];
});

// POST Update isBookmarked
mock.onPost("/api/bookmarks/update").reply((config) => {
  const { id } = JSON.parse(config.data);

  const obj = searchArr[0].data.find((item) => item.id === id);

  Object.assign(obj, { isBookmarked: !obj.isBookmarked });

  return [200];
});
