import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  announcements: [],
}
const announcementsSlice = createSlice({
  name: 'announcements',
  initialState: initialState,
  reducers: {
    showAnnouncements: (state, action) => {
      state.announcements = action.payload
    },
    showUserAnno:(state,action) => {
      state.announcements.push(action.payload)
    },
    showDeleted:(state,action) => {
      state.announcements = state.announcements.filter(
        (data)=>data.id !== action.payload
    )
    },
    showupdateAnno:(state,action)=> {
      const updateIndex = state.announcements.findIndex(
        (data) => data.id === action.payload.id
      );
      if (updateIndex !== -1) {
        state.announcements[updateIndex].passive = action.payload.passive;
      }

  },
  setActiveStatus: (state, action) => {
    const updateIndex = state.announcements.findIndex(
      (data) => data.id === action.payload.id
    );
    if (updateIndex !== -1) {
      state.announcements[updateIndex].passive = 1;
    }
  },
  setPassiveStatus: (state, action) => {
    const updateIndex = state.announcements.findIndex(
      (data) => data.id === action.payload.id
    );
    if (updateIndex !== -1) {
      state.announcements[updateIndex].passive = 0; 
    }
  },
  },
})
export const { showAnnouncements,showUserAnno,showDeleted,showupdateAnno, setPassiveStatus ,setActiveStatus } =
announcementsSlice.actions

export default announcementsSlice.reducer
