export const ApartmentTypes = {
  SHOW_APARTMENT: 'SHOW_APARTMENT',
  ADD_APARTMENT: 'ADD_APARTMENT',
  UPDATE_APARTMENT: 'UPDATE_APARTMENT',
  DELETE_APARTMENT: 'DELETE_APARTMENT',
  SHOW_APARTMENT_DEBT: 'SHOW_APARTMENT_DEBT',
  SHOW_ALL_APARTMENT_DEBT: 'SHOW_APARTMENT_DEBT',
  DELETE_APARTMENT_DEBT: 'DELETE_APARTMENT_DEBT',
  SHOW_APARTMENT_DEBT_DETAIL: 'SHOW_APARTMENT_DEBT_DETAIL',
  SHOW_APARTMENT_COLLECTIONS: 'SHOW_APARTMENT_COLLECTIONS',
  SHOW_APARTMENT_DEBT_GROUPS: 'SHOW_APARTMENT_DEBT_GROUPS',
  ADD_APARTMENT_DEBT_GROUPS: 'ADD_APARTMENT_DEBT_GROUPS',
  DELETE_APARTMENT_DEBT_GROUPS: 'DELETE_APARTMENT_DEBT_GROUPS',
  SHOW_APARTMENT_DETAIL: 'SHOW_APARTMENT_DETAIL',
  SHOW_APARTMENT_OWN: 'SHOW_APARTMENT_OWN',
  SHOW_APARTMENT_TENANT: 'SHOW_APARTMENT_TENANT',
  ADD_APARTMENT_RESIDENT: 'ADD_APARTMENT_RESIDENT',
  DELETE_APARTMENT_OWN: 'DELETE_APARTMENT_OWN',
  ADD_APARTMENT_TENANT: 'ADD_APARTMENT_TENANT',
  DELETE_APARTMENT_TENANT: 'DELETE_APARTMENT_TENANT',
  SHOW_STOCK_HOLDER: 'SHOW_STOCK_HOLDER',
  ADD_STOCK_HOLDER: 'ADD_STOCK_HOLDER',
  ADD_STOCK_HOLDER_NEW: 'ADD_STOCK_HOLDER_NEW',
  UPDATE_STOCK_HOLDER: 'UPDATE_STOCK_HOLDER',
  UPDATE_STOCK_HOLDER_SLICE: 'UPDATE_STOCK_HOLDER_SLICE',
  ADD_STOCK_HOLDER_BY_RESIDENT: 'ADD_STOCK_HOLDER_BY_RESIDENT',
  DELETE_STOCK_HOLDER: 'DELETE_STOCK_HOLDER',
  SHOW_FILTER_APARTMENTS: 'SHOW_FILTER_APARTMENTS',
  ADD_PAYMENT_DEBT_APARTMENT: 'ADD_PAYMENT_DEBT_APARTMENT',
  SEND_APARTMENT_DELETE_CODE: 'SEND_APARTMENT_DELETE_CODE',
  ADD_EXCEL_APARTMENT: 'ADD_EXCEL_APARTMENT',
}
