import axios from 'axios'
import toast from 'react-hot-toast'
import { application } from '../../../../../redux/store'
import { takeEvery, all, call, put } from 'redux-saga/effects'
import { getLicense, getReceipts } from './slice'
import { licenseTypes } from './types'

function* getLicenseHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/user/receipt?page=${page}`)
    )
    yield put(getLicense(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* getReceiptsHandler({ receipt_id }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/user/receipt/file/${receipt_id}`)
    )
    yield put(getReceipts(response.data.data))
  } catch (error) {
    yield put(getReceipts([]))
  }
}

export function* LicenseSagas() {
  yield all([takeEvery(licenseTypes.GET_LICENSE, getLicenseHandler)])
  yield all([takeEvery(licenseTypes.GET_RECEIPTS, getReceiptsHandler)])
}
