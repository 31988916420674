import axios from "axios";
import { takeEvery, put, call, all } from "redux-saga/effects";
import { AdminLoginTypes } from "./types";
import { numberReplace } from "../../../../../functions/Number/numberReplace";
import toast from "react-hot-toast";
import {  loginSuccess } from "./slice";
import { application } from "../../../../../redux/store";
import { userInformation } from "../../Login/store/slice";

function* loginAdminHandler({ payload }) {
  try {
    const response = yield call(() =>
     axios.post(`${application.api}/login/user/admin`, {
        phone: numberReplace(payload.phone),
        password: payload.password,
      })
    );
    localStorage.setItem("auth", response.data.data.token);
    yield put(userInformation(payload));
    yield put(loginSuccess(true));
    toast.success("Giriş Başarıyla Tamamlandı");
  } catch (error) {
    toast.error(error.response.data.description);
    yield put(loginSuccess(false));
  }
}

export function* LoginAdminSagas() {
  yield all([takeEvery(AdminLoginTypes.LOGIN_ADMIN, loginAdminHandler)]);
}
