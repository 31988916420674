import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  debt_reports: [],
  debt_report_detail: []
};
const DebtReportsSlice = createSlice({
  name: "DebtReportsSlice",
  initialState: initialState,
  reducers: {
    showDebtReports: (state, action) => {
      state.debt_reports = action.payload;
    },
    showDebtReportDetail: (state, action) => {
      state.debt_report_detail = action.payload;
    },
  },
});
export const { showDebtReports, showDebtReportDetail } = DebtReportsSlice.actions;
export default DebtReportsSlice.reducer;
