import axios from "axios";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { updateInterest } from "./slice";
import { InterestSettings } from "./types";
import { application } from "../../../../../redux/store";
import toast from "react-hot-toast";

function* updateInterestHandler({ payload }) {
  try {
    axios.put(`${application.api}/interest/setting`, { payload });
    yield put(updateInterest(payload));
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

export function* InterestSagas() {
  yield all([
    takeEvery(InterestSettings.UPDATE_INTEREST_SETTINGS, updateInterestHandler),
  ]);
}