import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nonMembers: [],
  smsPreview: {},
  is_filter_load:false
};
const ToNonMembersSmsSlice = createSlice({
  name: "toNonMembersSms",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showNonMemberss: (state, action) => {
      state.nonMembers = action.payload;
    },
    showSmsPreview: (state, action) => {
      state.smsPreview = action.payload;
    },
  },
});
export const { showNonMemberss, showSmsPreview, filterLoad } = ToNonMembersSmsSlice.actions;
export default ToNonMembersSmsSlice.reducer;
