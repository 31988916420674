import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { ApartmentSingleTypes } from './types'
import { showApartment, addSingleDebt, filterLoad } from './slice'
import toast from 'react-hot-toast'
function* showSingleDebtHandler({ payload }) {
  try {
    const buildings =
      payload.id?.length > 0 ? payload.id?.map((item) => item.id) : null
    const response = yield call(() =>
      axios.post(`${application.api}/apartments/only/resident`, {
        buildings: buildings?.length > 0 ? buildings : null,
      })
    )
    yield put(showApartment(response.data.data))
    yield put(filterLoad(false))
  } catch (error) {}
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* addSingleDebtHandler({ payload }) {
  // girilmemiş amount  verileri backende gönderilmiyor
  if (!payload.is_interest) {
    payload.interest = null
  }
  payload.apartments = payload.apartments.filter(
    (el) => parseFloat(el.amount) > 0
  )
  payload.apartments = payload.apartments.map(
    (item) => (
      (item.amount = item.amount.replaceAll(',', '.')),
      {
        apartment_id: item.apartment_id,
        amount: parseFloat(item.amount),
        apartment_identifier: item.identifier,
        type: item.type,
        building_id: item.building_id,
        building_identifier: item.building_identifier,
      }
    )
  )
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/single`, payload)
    )
    toast.success(response.data.description)
    yield put(addSingleDebt({ debts_error: response.data.data }))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
export function* ApartmentSingleSagas() {
  yield all([
    takeEvery(
      ApartmentSingleTypes.SHOW_APARTMENT_SINGLE_DEBT,
      showSingleDebtHandler
    ),
    takeEvery(
      ApartmentSingleTypes.SHOW_APARTMENT_SINGLE_DEBT,
      filterLoadHandler
    ),
    takeEvery(ApartmentSingleTypes.ADD_SINGLE_DEBT, addSingleDebtHandler),
  ])
}
