import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    reports:[]
}

const SmsReportSlice = createSlice({
    name:"smsreports",
    initialState:initialState,
    reducers:{
        showSmsReports:(state,action)=>{
            state.reports = action.payload
        }
    }
})

export const {showSmsReports} = SmsReportSlice.actions

export default SmsReportSlice.reducer