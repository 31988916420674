import { all } from 'redux-saga/effects'
import { DebtTypesSaga } from '../views/apps/definitions/accountingItems/store/saga'
import { DefDebtGroupSagas } from '../views/apps/definitions/debtGroups/store/saga'
import { AccountSagas } from '../views/apps/definitions/register/store/saga'
import { ApartmentSagas } from '../views/apps/residents/apartmentOpt/store/saga'
import { BuildingSagas } from '../views/apps/residents/buildingOpt/store/saga'
import { residentSagas } from '../views/apps/residents/residentOpt/store/saga'
import { LoginSagas } from '../views/pages/authentication/Login/store/saga'
import { RegisterSagas } from '../views/pages/authentication/Register/store/saga'
import { rootSearchSagas } from './searchServices/saga'
import { GroupDebtSagas } from '../views/apps/debit/groupDebt/store/saga'
import { CurrentAccountSagas } from '../views/apps/definitions/currentAccounts/store/saga'
import { DefinationSagas } from '../views/apps/definitions/store/saga'
import { DebtExemptionSagas } from '../views/apps/definitions/debtExemption/store/saga'
import { SubAccountsSagas } from '../views/apps/definitions/subAccounts/store/saga'
import { AddinitionalFeaturesSagas } from '../views/apps/additionalFeatures/store/saga'
import { ReportsSagas } from '../views/apps/reports/store/saga'
import { DefDueGroupSagas } from '../views/apps/definitions/dueGroups/store/saga'
import { rootFilterSagas } from './filterSercives/saga'
import { ApartmentSingleSagas } from '../views/apps/debit/singleDebt/store/saga'
import { BuildingDebtSagas } from '../views/apps/debit/buildingAutoDebt/store/saga'
import { LandDebtSagas } from '../views/apps/debit/landDebt/store/saga'
import { AreaDebtSagas } from '../views/apps/debit/area/store/saga'
import { DebtRecordsSagas } from '../views/apps/debit/debtReports/store/saga'
import { EqualDebtSagas } from '../views/apps/debit/equalDebt/store/saga'
import { PersonDebtSagas } from '../views/apps/debit/personDebt/store/saga'
import { UserSagas } from '../views/apps/profile/store/saga'
import { PasswordSagas } from '../views/apps/profile/changePassword/store/saga'
import { SubUserSagas } from '../views/apps/additionalFeatures/subUser/store/saga'
import { AccountCollectionSagas } from '../views/apps/accounting/collection/store/saga'
import { AccountPaymentSagas } from '../views/apps/accounting/incomeRecords/store/saga'
import { AccountCurrentSagas } from '../views/apps/accounting/currentRecords/store/saga'
import { AccountExpenseSagas } from '../views/apps/accounting/expenseRecords/store/saga'
import { smsTemplateSagas } from '../views/apps/smsOpt/smsTemplates/store/saga'
import { SmsReportSagas } from '../views/apps/smsOpt/smsReports/store/saga'
import { PollSagas } from '../views/apps/smsOpt/pollOpt/store/saga'
import { VoteSagas } from '../views/pages/vote/store/saga'
import { StaffPaymentSagas } from '../views/apps/employee/payment/store/saga'
import { LogsSagas } from '../views/apps/logsStore/saga'
import { ToDebtSmsSagas } from '../views/apps/smsOpt/collectiveSms/toDebt/store/saga'
import { SmsJobsSagas } from '../views/apps/smsOpt/smsJobs/store/saga'
import { IncomeTableSagas } from '../views/apps/reports/tables/incomeTable/store/saga'
import { ExpenseTableSagas } from '../views/apps/reports/tables/expenseTable/store/saga'
import { CashBookSagas } from '../views/apps/reports/tables/cashBook/store/saga'
import { BusinessLedgerSagas } from '../views/apps/reports/tables/businessLedger/store/saga'
import { AccountActivitiesSagas } from '../views/apps/reports/tables/accountActivities/store/saga'
import { AdvanceRecordsSagas } from '../views/apps/reports/tables/advanceRecords/store/saga'
import { TransferRecordsSagas } from '../views/apps/reports/tables/transferRecords/store/saga'
import { BalanceReportSagas } from '../views/apps/reports/tables/balanceSheet/store/saga'
import { InterestReportSagas } from '../views/apps/reports/tables/totalInterestList/store/saga'
import { InterestSagas } from '../views/apps/definitions/interestSettings/store/saga'
import { BranchesSagas } from '../views/pages/authentication/Branches/store/saga'
import { ToResidentSmsSagas } from '../views/apps/smsOpt/collectiveSms/toResidents/store/saga'
import { ToBuildingsSmsSagas } from '../views/apps/smsOpt/collectiveSms/toBuildings/store/saga'
import { VirmanSagas } from '../views/apps/accounting/transfer/store/saga'
import { SmsNonMembersSagas } from '../views/apps/smsOpt/nonMemberSms/store/saga'
import { ToNonMembersSmsSagas } from '../views/apps/smsOpt/collectiveSms/toNonMembers/store/saga'
import { PackageSagas } from '../views/apps/settings/balance/store/saga'
import { UserSettingsSagas } from '../views/apps/settings/user-settings/store/saga'
import { ToGroupSmsSagas } from '../views/apps/smsOpt/collectiveSms/toGroup/store/saga'
import { ForgotPasswordSagas } from '../views/pages/authentication/ForgotPassword/store/saga'
import { TodoListsSagas } from '../views/dashboard/ecommerce/containers/todo/store/saga'
import { DashboardTablesSagas } from '../views/dashboard/ecommerce/views/Charts/store/saga'
import { StaffPermissionSaga } from '../views/apps/employee/permission/store/saga'
import { PollSmsSagas } from '../views/apps/smsOpt/pollSms/store/saga'
import { ExcelDebtSagas } from '../views/apps/debit/excelDebt/store/saga'
import { LicenseSagas } from '../views/apps/settings/licence/store/saga'
import { ReceiptSagas } from '../views/apps/accounting/receipt/store/saga'
import { LoginAdminSagas } from '../views/pages/authentication/LoginAdmin/store/saga'
import { TicketSagas } from '../views/apps/support/ticket/store/saga'
import { UserSearchSagas } from '../@core/layouts/components/navbar/store/saga'
import { announcementsSagas } from '../views/apps/additionalFeatures/announcement/store/saga' 
import { ConversationSagas } from '../views/apps/support/user-saves/store/saga'

export function* rootSaga() {
  yield all([
    DefDebtGroupSagas(),
    DebtTypesSaga(),
    rootSearchSagas(),
    AccountSagas(),
    BuildingSagas(),
    ApartmentSagas(),
    residentSagas(),
    RegisterSagas(),
    LoginSagas(),
    CurrentAccountSagas(),
    DefinationSagas(),
    DebtExemptionSagas(),
    SubAccountsSagas(),
    AddinitionalFeaturesSagas(),
    ReportsSagas(),
    DefDueGroupSagas(),
    rootFilterSagas(),
    ApartmentSingleSagas(),
    BuildingDebtSagas(),
    LandDebtSagas(),
    AreaDebtSagas(),
    GroupDebtSagas(),
    DebtRecordsSagas(),
    EqualDebtSagas(),
    PersonDebtSagas(),
    UserSagas(),
    PasswordSagas(),
    SubUserSagas(),
    AccountCollectionSagas(),
    AccountPaymentSagas(),
    AccountCurrentSagas(),
    AccountExpenseSagas(),
    smsTemplateSagas(),
    SmsReportSagas(),
    PollSagas(),
    VoteSagas(),
    StaffPaymentSagas(),
    LogsSagas(),
    ToDebtSmsSagas(),
    SmsJobsSagas(),
    IncomeTableSagas(),
    ExpenseTableSagas(),
    CashBookSagas(),
    BusinessLedgerSagas(),
    AccountActivitiesSagas(),
    AdvanceRecordsSagas(),
    TransferRecordsSagas(),
    BalanceReportSagas(),
    InterestReportSagas(),
    InterestSagas(),
    BranchesSagas(),
    ToResidentSmsSagas(),
    ToBuildingsSmsSagas(),
    ToNonMembersSmsSagas(),
    VirmanSagas(),
    SmsNonMembersSagas(),
    PackageSagas(),
    UserSettingsSagas(),
    ToGroupSmsSagas(),
    ForgotPasswordSagas(),
    TodoListsSagas(),
    DashboardTablesSagas(),
    StaffPermissionSaga(),
    PollSmsSagas(),
    ExcelDebtSagas(),
    LicenseSagas(),
    ReceiptSagas(),
    LoginAdminSagas(),
    TicketSagas(),
    UserSearchSagas(),
    announcementsSagas(),
    ConversationSagas(),
    UserSearchSagas()
  ])
}
