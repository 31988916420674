import axios from "axios";
/*eslint-disable */
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { ApartmentAreaTypes } from "./types";
import { showApartment, addLandDebt, filterLoad } from "./slice";
import toast from "react-hot-toast";
function* showAreaDebtHandler({ payload }) {
    try {
        const buildings =
            payload.id?.length > 0 ? payload.id?.map((item) => item.id) : null;
        const response = yield call(() =>
            axios.post(`${application.api}/apartments/only/resident`, {
                buildings: buildings?.length > 0 ? buildings : null,
                area: 1,
            })
        );
        yield put(showApartment(response.data.data));
        yield put(filterLoad(false))
} catch (error) {
        yield put(showApartment(error.response.data.data));
    }
}

function* filterLoadHandler() {
    yield put(filterLoad(true))
  }

function* addAreaDebtHandler({ payload }) {
    if (!payload.is_interest) {
        payload.interest = null;
    }
    // girilmemiş amount  verileri backende gönderilmiyor
    payload.apartments = payload.apartments.filter(
        (el) => parseFloat(el.amount) > 0
    );
    payload.apartments = payload.apartments.map((item) =>
        // item.amount = item.amount?.replaceAll(",", "."),
        ({
            apartment_id: item.apartment_id,
            amount: parseFloat(item.amount),
            identifier: item.identifier,
            type: item.type,
            resident_id: item.resident_id,
            building_identifier: item.building_name,
        })
    );
    try {
        const response = yield call(() =>
            axios.post(`${application.api}/debt/area`, payload)
        );
        toast.success(response.data.description);
        yield put(addLandDebt({ debts_error: response.data.data }));
    } catch (error) {
        toast.error(error.response.data.description);
    }
}
export function* AreaDebtSagas() {
    yield all([
        takeEvery(
            ApartmentAreaTypes.SHOW_APARTMENT_AREA_DEBT,
            showAreaDebtHandler
        ),
        takeEvery(
            ApartmentAreaTypes.SHOW_APARTMENT_AREA_DEBT,
            filterLoadHandler
        ),
        takeEvery(ApartmentAreaTypes.ADD_AREA_DEBT, addAreaDebtHandler),
    ]);
}
