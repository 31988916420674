import { createSlice } from '@reduxjs/toolkit'
import moment from "moment";

const initialState = {
    incomeTable: {},
    totalIncome: 0,
    is_filter_load:false
}

const IncomeSlice = createSlice({
    name:"incomeTableSlice",
    initialState:initialState,
    reducers: {
        filterLoad:(state,action)=>{
            state.is_filter_load = action.payload
        },
        showIncomeTable: (state, action) => {
            action.payload.map((item) => (
                item.start_date = moment(item.date).format("DD.MM.YYYY"),
                item.end_date = moment(item.date).format("DD.MM.YYYY")
            ))
            state.incomeTable = action.payload
        },
        
        showTotalIncome: (state, action) => {
            state.totalIncome = action.payload
        },
    }
})

export const { showIncomeTable, showTotalIncome,filterLoad } = IncomeSlice.actions

export default IncomeSlice.reducer