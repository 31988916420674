import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  buildingKey: [],
  buildings: [],
  apartmentKey: [],
  apartments: [],
  apartmentSingleKey: [],
  apartmentSingle: [],
  apartmentNoKey: [],
  apartmentsNo: [],
  apartmentNoSingleKey: [],
  apartmentNoSingle: [],
  accountKey: [],
  accounts: [],
  types: [],
  typeKey: [],
  debtGroups: [],
  debtGroupsKey: [],
  currents: [],
  currentKey: [],
  residents: [],
  residentKey: [],
  smsTemplates: [],
  smsTemplateKey: [],
  staffs: [],
  staffKey: [],
  surveys: [],
  surveyKey: [],
};
const SearchSelectSlice = createSlice({
  name: "BuildingsSlice",
  initialState: initialState,
  reducers: {
    getAccount: (state, action) => {
      state.accounts = action.payload;
    },
    getSearchAccount: (state, action) => {
      state.accountKey = action.payload;
    },
    getBuilding: (state, action) => {
      state.buildings = action.payload;
    },
    getSearchBuilding: (state, action) => {
      state.buildingKey = action.payload;
    },
    getApartment: (state, action) => {
      state.apartments = action.payload;
    },
    getSearchApartment: (state, action) => {
      state.apartmentKey = action.payload;
    },
    getApartmentSingle: (state, action) => {
      state.apartmentSingle = action.payload;
    },
    getSearchApartmentSingle: (state, action) => {
      state.apartmentSingleKey = action.payload;
    },
    getApartmentNo: (state, action) => {
      state.apartmentsNo = action.payload;
    },
    getSearchApartmentNo: (state, action) => {
      state.apartmentNoKey = action.payload;
    },
    getApartmentNoSingle: (state, action) => {
      state.apartmentNoSingle = action.payload;
    },
    getSearchApartmentNoSingle: (state, action) => {
      state.apartmentNoSingleKey = action.payload;
    },
    getTypes: (state, action) => {
      state.types = action.payload;
    },
    getSearchTypes: (state, action) => {
      state.typeKey = action.payload;
    },
    getDebtGroups: (state, action) => {
      state.debtGroups = action.payload;
    },
    getSearchDebtGroups: (state, action) => {
      state.debtGroupsKey = action.payload;
    },
    getCurrents: (state, action) => {
      state.currents = action.payload;
    },
    getSearchCurrent: (state, action) => {
      state.currentKey = action.payload;
    },
    getResidents: (state, action) => {
      state.residents = action.payload;
    },
    getSearchResident: (state, action) => {
      state.residentKey = action.payload;
    },
    getSmsTemplate: (state, action) => {
      state.smsTemplates = action.payload;
    },
    getSearchSmsTemplate: (state, action) => {
      state.smsTemplateKey = action.payload;
    },
    getStaff: (state, action) => {
      state.staffs = action.payload;
    },
    getSearchStaff: (state, action) => {
      state.staffKey = action.payload;
    },
    getSurvey: (state, action) => {
      state.surveys = action.payload;
    },
    getSearchSurvey: (state, action) => {
      state.surveyKey = action.payload;
    },
  },
});
export const {
  getSearchBuilding,
  getBuilding,
  getAccount,
  getSearchAccount,
  getTypes,
  getSearchTypes,
  getDebtGroups,
  getSearchDebtGroups,
  getApartment,
  getSearchApartment,
  getApartmentSingle,
  getSearchApartmentSingle,
  getApartmentNo,
  getSearchApartmentNo,
  getApartmentNoSingle,
  getSearchApartmentNoSingle,
  getCurrents,
  getSearchCurrent,
  getResidents,
  getSearchResident,
  getSmsTemplate,
  getSearchSmsTemplate,
  getStaff,
  getSearchStaff,
  getSurvey,
  getSearchSurvey,
} = SearchSelectSlice.actions;
export default SearchSelectSlice.reducer;
