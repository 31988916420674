import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: [],
  staffs: [],
};

const StaffPermissionSlice = createSlice({
  name: "StaffPermissionSlice",
  initialState: initialState,
  reducers: {
    showStaffPermission: (state, action) => {
      state.permissions = action.payload;
    },
    showStaff: (state, action) => {
      state.staffs = action.payload;
    },
    // showAccount: (state, action) => {
    //   state.accounts = action.payload;
    // },
  },
});
export const {
  showStaffPermission,
  showStaff,
  // deleteStaffPayment,
  // showStaff,
  // showAccount,
} = StaffPermissionSlice.actions;

export default StaffPermissionSlice.reducer;
