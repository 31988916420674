import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../redux/store'
import { AddinitionalFeaturesTypes } from './types'
import toast from 'react-hot-toast'
import {
  addDocument,
  addGuest,
  deleteDocument,
  deleteGuest,
  showDocument,
  showGuest,
  updateGuest,
} from './slice'
import moment from 'moment'

function* showGuestHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/guests?page=${page}&type=1`)
    )
    yield put(showGuest(response.data.data.Records))
  } catch (error) {}
}

function* showGuestFilterHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/guests?start_date=${payload.data.start_date}&end_date=${payload.data.end_date}&apartment_id=${payload.data.apartment_id}&page=${payload.page}&type=1`
      )
    )
    if (response.data.data.Records.length > 0) {
      yield put(showGuest(response.data.data.Records))
      toast.success('Ziyaretler Listelendi')
    } else {
      toast.error('Apartmana ait Ziyaret Bulunamadı')
    }
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* newGuestHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/guest`, payload.data)
    )
    // yield put(addGuest(response.data.data))
    //backend response düzgün döndmediği için tekrar iste arttırmak zorunda kaldım
    yield showGuestHandler(payload)

    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteGuestHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/guest`, {
        data: {
          id: payload.data,
        },
      })
    )
    yield put(deleteGuest(payload))
    yield showGuestHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateGuestHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/guest`, payload.data)
    )
    // yield put(updateGuest(payload.data))
    // yield put(addGuest(response.data.data))
    //backend response düzgün döndmediği için tekrar iste arttırmak zorunda kaldım
    yield showGuestHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showDocumentHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/documents?page=${page}`)
    )
    yield put(showDocument(response.data.data.Records))
  } catch (error) {}
}

function* updateDocumentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/document`, {
        id: payload.id,
        description: payload.description,
        date: payload.date,
        fileName: payload.file_name,
      })
    )
    toast.success(response.data.description)
    yield showDocumentHandler(1)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addDocumentHandler({ payload, page }) {
  const formdata = new FormData()
  const fileName = payload.file.file.name
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
  formdata.append('file_name', fileName)
  formdata.append('File', payload.file.file)
  formdata.append('description', payload.description)
  formdata.append('date', payload.date)
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/document`, formdata)
    )
    yield put(addDocument(response.data.data))
    yield showDocumentHandler(payload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* downloadDocumentHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/download/documents`, {
        params: {
          file_name: payload.file_name,
        },
      })
    )
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteDocumentHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/document`, {
        data: {
          id: payload,
        },
      })
    )
    yield put(deleteDocument(payload))
    yield showDocumentHandler({ page })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* AddinitionalFeaturesSagas() {
  yield all([
    takeEvery(AddinitionalFeaturesTypes.SHOW_GUEST, showGuestHandler),
    takeEvery(AddinitionalFeaturesTypes.ADD_GUEST, newGuestHandler),
    takeEvery(AddinitionalFeaturesTypes.DELETE_GUEST, deleteGuestHandler),
    takeEvery(AddinitionalFeaturesTypes.UPDATE_GUEST, updateGuestHandler),
    takeEvery(
      AddinitionalFeaturesTypes.SHOW_GUEST_FILTER,
      showGuestFilterHandler
    ),
    takeEvery(AddinitionalFeaturesTypes.SHOW_DOCUMENT, showDocumentHandler),
    takeEvery(AddinitionalFeaturesTypes.UPDATE_DOCUMENT, updateDocumentHandler),
    takeEvery(AddinitionalFeaturesTypes.ADD_DOCUMENT, addDocumentHandler),
    takeEvery(
      AddinitionalFeaturesTypes.DOWNLOAD_DOCUMENT,
      downloadDocumentHandler
    ),
    takeEvery(AddinitionalFeaturesTypes.DELETE_DOCUMENT, deleteDocumentHandler),
  ])
}
