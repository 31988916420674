import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  debtGroups: [],
  is_posted:false
}
const DefDebtGroupSlice = createSlice({
  name: 'debtGroupSlice',
  initialState: initialState,
  reducers: {
    handlePost:(state,action)=>{
      state.is_posted = action.payload
    },
    showDebtGroup: (state, action) => {
      state.debtGroups = action.payload
    },
    addDebtGroup: (state, action) => {
      state.debtGroups.push(action.payload)
    },
    updateDebtGroup: (state, action) => {
      const updateIndex = state.debtGroups.findIndex(
        (el) => el.id === action.payload.id
      )
      state.debtGroups[updateIndex] = action.payload
    },
    deleteDebtGroup: (state, action) => {
      state.debtGroups = state.debtGroups.filter(
        (el) => el.id !== action.payload
      )
    },
  },
})
export const { handlePost, showDebtGroup, addDebtGroup, updateDebtGroup, deleteDebtGroup } =
  DefDebtGroupSlice.actions
export default DefDebtGroupSlice.reducer
