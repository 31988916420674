import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currents: [],
};
const AccountCurrentSlice = createSlice({
  name: "collection", //????????? current olması gerekmiyor mu
  initialState: initialState,
  reducers: {
    showCurrent: (state, action) => {
      state.currents = action.payload;
      state.currents.map(
        (current) => (
          (current.current_account = current.current_account?.name),
          (current.accounting_type = current.accounting_type?.name)
        )
      );
    },
    addCurrent: (state, action) => {
      state.currents.push(action.payload);
    },
    updateCurrent: (state, action) => {
      const currentIndex = state.currents.findIndex(
        (el) => el.id === action.payload.id
      );
      state.currents[currentIndex] = action.payload;
    },
    deleteCurrent: (state, action) => {
      state.currents = state.currents.filter((el) => el.id !== action.payload);
    },
  },
});
export const { showCurrent, addCurrent, updateCurrent, deleteCurrent } =
  AccountCurrentSlice.actions;

export default AccountCurrentSlice.reducer;
