import { createSlice } from "@reduxjs/toolkit"
const initialState = {
    buildings: [],
    groups: []

}
const FilterSlice = createSlice({
    name: 'FilterSlice',
    initialState: initialState,
    reducers: {
        showBuilding: (state, action) => {
            state.buildings = action.payload
        },
        showGroup: (state, action) => {
            state.groups = action.payload
        },
    }
})
export const { showBuilding, showGroup } = FilterSlice.actions
export default FilterSlice.reducer