import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../../redux/store";
import { showNonMemberss, showSmsPreview, showSmsTemplates,filterLoad } from "./slice";
import { ToNonMembersSmsTypes } from "./types";

function* getNonMembersSmsHandler({ payload, page }) {
  const response = yield call(() =>
    axios.get(
      `${application.api}/non/members?page=${page}${
        payload.name ? `&name=${payload.name}` : ""
      }${payload.phone ? `&phone=${payload.phone}` : ""}`
    )
  );
  yield put(showNonMemberss(response.data.data.Records));
  yield put(filterLoad(false))
}

function* filterLoadHandler() {
  yield put(filterLoad(true))
}

function* sendSmsPreviewHandler({ payload }) {
  payload.sms_template_id = payload.sms_template.id;
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/non/member/sms/preview`, {
        all_data: payload?.all_data,
        future_date: {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          start_date: payload?.date,
        },
        is_future_date: payload?.is_future_date,
        message: payload?.message,
        name: payload?.name,
        national_id: payload?.national_id,
        not_sending: payload?.not_sending,
        phone: payload?.phone,
        sending: payload?.sending,
        sms_template_id: payload?.sms_template_id,
      })
    );
    yield put(showSmsPreview(response.data.data));
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* sendSmsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/non/member/sms/send`, {
        all_data: payload?.all_data,
        future_date: {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          start_date: payload?.date,
        },
        is_future_date: payload?.is_future_date,
        message: payload?.message,
        name: payload?.name,
        national_id: payload?.national_id,
        not_sending: payload?.not_sending,
        phone: payload?.phone,
        sending: payload?.sending,
        sms_template_id: payload?.sms_template_id,
      })
    );
    if (payload.continually) {
      yield call(() =>
        axios.post(`${application.api}/sms/jobs`, {
          hour: parseInt(payload.hour),
          minute: payload.minute,
          job_type: 0,
          last_date: payload.continually_date,
          message: payload.message,
          name: "test",
          resident_ids: payload.sending,
        })
      );
    }
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
export function* ToNonMembersSmsSagas() {
  yield all([
    takeEvery(
      ToNonMembersSmsTypes.GET_SMS_NON_MEMBERS,
      getNonMembersSmsHandler
    ),
    takeEvery(
      ToNonMembersSmsTypes.GET_SMS_NON_MEMBERS,
      filterLoadHandler
    ),
    takeEvery(
      ToNonMembersSmsTypes.SEN_SMS_NON_MEMBERS_PREVIEW,
      sendSmsPreviewHandler
    ),
    takeEvery(ToNonMembersSmsTypes.SEN_SMS_NON_MEMBERS, sendSmsHandler),
  ]);
}
