import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dashboardTables:[],
}

const DashboardTablesSlice = createSlice({
    name:"dashboardTablesSlice",
    initialState:initialState,
    reducers: {
        showDashboardTables: (state, action) => {
            state.dashboardTables = action.payload
        }
    }
})

export const { showDashboardTables } = DashboardTablesSlice.actions

export default DashboardTablesSlice.reducer