import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apartmentsSingleDebt: [],
  surveys: [],
  buildings: [],
  smsPreview: {},
  isSubmit: null,
  is_filter_load:false
};
const PollSmsSlice = createSlice({
  name: "PollSmsSlice",
  initialState: initialState,
  reducers: {
    filterLoad:(state,action)=>{
      state.is_filter_load = action.payload
    },
    showSurvey: (state, action) => {
      state.surveys = action.payload;
    },
    showBuildings: (state, action) => {
      state.buildings = action.payload;
    },
    showSmsPreview: (state, action) => {
      state.smsPreview = action.payload;
    },
    isSubmitSms: (state, action) => {
      state.isSubmit = action.payload;
    },
  },
});
export const { showSurvey, showSmsPreview, showBuildings, isSubmitSms,filterLoad } =
  PollSmsSlice.actions;
export default PollSmsSlice.reducer;
