import axios from "axios";
/*eslint-disable */
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import toast from "react-hot-toast";
import { BuildingDebtTypes } from "./types";
function* addBuildingDebtHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/multi`, payload)
    );
    toast.success(response.data.description)
    // yield put(addBuildingDebt(response.data.data));
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* BuildingDebtSagas() {
  yield all([
    takeEvery(BuildingDebtTypes.ADD_BUILDING_DEBT, addBuildingDebtHandler),
  ]);
}
