
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isLogin: false,
}
const LoginAdminSlice = createSlice({
    name: 'LoginAdminSlice',
    initialState: initialState,
    reducers: {
        loginSuccess: (state,action) => {
            state.isLogin = action.payload
        }
    }
})
export const { loginSuccess } = LoginAdminSlice.actions
export default LoginAdminSlice.reducer