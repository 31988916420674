import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  apartmentsEqualDebt: [],
  debts: [],
  debts_error: [],
  is_filter_load: false,
}
const EqualDebtSlice = createSlice({
  name: 'ApartmentSingleDebtSlice',
  initialState: initialState,
  reducers: {
    filterLoad: (state, action) => {
      state.is_filter_load = action.payload
    },
    showApartment: (state, action) => {
      state.apartmentsEqualDebt = action.payload
    },
    addEqualDebt: (state, action) => {
      state.debts_error = action.payload.debts_error
    },
  },
})
export const { showApartment, addEqualDebt, filterLoad } =
  EqualDebtSlice.actions
export default EqualDebtSlice.reducer
