import axios from "axios";
import toast from "react-hot-toast";
import { application } from "../../../../../../redux/store";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { showBalanceReport } from "./slice";
import { BalanceReportTypes } from "./types";

function* getBalanceReport({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/balance/report`, {
        start_date: payload.start_date,
        end_date: payload.end_date,
      })
    );

    yield put(showBalanceReport(response.data.data));
  } catch (error) {}
}

export function* BalanceReportSagas() {
  yield all([
    takeLatest(BalanceReportTypes.GET_BALANCE_REPORT, getBalanceReport),
  ]);
}
