import axios from "axios";
import { takeEvery, put, call, all } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { StaffPaymentTypes, StaffPermissionType } from "./types";
import toast from "react-hot-toast";
import { showStaff, showStaffPermission } from "./slice";

function* showPermissionHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/vacations?page=${page}`)
    );
    yield put(showStaffPermission(response.data.data.Records));
  } catch (error) {}
}
function* showStaffHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/staffs?page=${page}`)
    );
    const filterResponse = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }));
    yield put(showStaff(filterResponse));
  } catch (error) {
    yield put(showStaff([]));
  }
}

function* deleteStaffPermissionHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/vacation`, {
        data: { vacation_id: payload.id },
      })
    );
    yield showPermissionHandler({ page: page });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* addStaffPermissionHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/vacation`, payload.data)
    );
    yield showPermissionHandler({ page: page });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}
function* updatetaffPermissionHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/vacation`, payload.data)
    );
    yield showPermissionHandler({ page: page });
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* showAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/accounts`, payload)
    );
    const filterResponse = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }));
    yield put(showAccount(filterResponse));
  } catch (error) {
    yield put(showAccount([]));
  }
}

export function* StaffPermissionSaga() {
  yield all([
    takeEvery(StaffPermissionType.SHOW_STAFF_PERMISSON, showPermissionHandler),
    takeEvery(StaffPermissionType.SHOW_STAFF_PERMISSON, showStaffHandler),
    takeEvery(
      StaffPermissionType.ADD_STAFF_PERMISSON,
      addStaffPermissionHandler
    ),
    takeEvery(
      StaffPermissionType.UPDATE_STAFF_PERMISSION,
      updatetaffPermissionHandler
    ),
    takeEvery(
      StaffPermissionType.DELETE_STAFF_PERMISSON,
      deleteStaffPermissionHandler
    ),
    // takeEvery(StaffPaymentTypes.SHOW_ACCOUNT, showAccountHandler),
  ]);
}
