import axios from "axios";
import { takeEvery, put, all, call } from "redux-saga/effects";
import { application } from "../../../../../redux/store";
import { AccountCurrentTypes } from "./types";
import toast from "react-hot-toast";
import { addCurrent, showCurrent } from "./slice";
import { deletePayment } from "../../incomeRecords/store/slice";
import moment from "moment";

function* showCurrentHandler({ page }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/current/records?page=${page}&type=1`)
    );

    yield put(showCurrent(response.data.data.Records));
  } catch (error) {}
}

function* newCurrentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/current/record`, payload.data)
    );
    yield put(addCurrent(payload.data));
    yield showCurrentHandler(payload);
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* updateCurrentHandler({ payload }) {
  //payload.date = moment(payload.date).format("DD.MM.YYYY");
  //payload.bill_date = moment(payload.bill_date).format("DD.MM.YYYY");

  try {
    const response = yield call(() =>
      axios.put(`${application.api}/current/record`, payload)
    );
    // yield put(updateCurrent(payload))
    //backende response tam dönmediği için  tekrar istek attırıldı
    yield showCurrentHandler(payload);
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

function* deleteCurrentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/current/record`, {
        data: {
          id: payload.data,
        },
      })
    );
    yield put(deletePayment(payload.data));
    yield showCurrentHandler(payload);
    toast.success(response.data.description);
  } catch (error) {
    toast.error(error.response.data.description);
  }
}

export function* AccountCurrentSagas() {
  yield all([
    takeEvery(AccountCurrentTypes.SHOW_CURRENT, showCurrentHandler),
    takeEvery(AccountCurrentTypes.ADD_CURRENT, newCurrentHandler),
    takeEvery(AccountCurrentTypes.DELETE_CURRENT, deleteCurrentHandler),
    takeEvery(AccountCurrentTypes.UPDATE_CURRENT, updateCurrentHandler),
  ]);
}
