import axios from 'axios'
import { put, all, takeEvery, call } from 'redux-saga/effects'
import { application } from '../store'
import {
  getAccount,
  getApartment,
  getApartmentNo,
  getApartmentNoSingle,
  getApartmentSingle,
  getBuilding,
  getCurrents,
  getDebtGroups,
  getResidents,
  getSearchAccount,
  getSearchApartment,
  getSearchApartmentNo,
  getSearchApartmentNoSingle,
  getSearchApartmentSingle,
  getSearchBuilding,
  getSearchCurrent,
  getSearchDebtGroups,
  getSearchResident,
  getSearchSmsTemplate,
  getSearchStaff,
  getSearchSurvey,
  getSearchTypes,
  getSmsTemplate,
  getStaff,
  getSurvey,
  getTypes,
} from './slice'
import { SelectServiceTypes } from './types'

function* showAccountHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/accounts`))
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getAccount(filterRecords))
  } catch (error) {}
}

function* searchAccountHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/accounts?key=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getSearchAccount(filterRecords))
  } catch (error) {}
}

function* showBuildingHandler() {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/buildings`,{all:true})
    )

    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getBuilding(filterRecords))
  } catch (error) {}
}

function* searchBuildingHandler({ payload }) {
  //İstek post olarak gittiği için keyi JSON olarak gönderiyoruz.
  try {
    const qs = require('qs')
    const response = yield call(() =>
      axios.post(
        `${application.api}/buildings`,
        qs.stringify({ key: payload.key ,all:true})
      )
    )

    yield put(getSearchBuilding(response.data.data))
  } catch (error) {}
}

function* showApartmentHandler() {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/buildings`,{all:true})
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getApartment(filterRecords))
  } catch (error) {}
}
function* searchApartmentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/all/apartments?key=${payload.key}`)
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getSearchApartment(filterRecords))
  } catch (error) {}
}

function* showApartmentSingleHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartments`, {
        building_id: [payload.id],
      })
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.name,
    }))

    yield put(getApartmentSingle(filterRecords))
  } catch (error) {}
}

function* searchApartmentSingleHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/apartments?key=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getSearchApartmentSingle(filterRecords))
  } catch (error) {}
}
//APARTMENT NO SECTION START
function* showApartmentNoHandler() {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/all/apartments`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.identifier,
    }))
    yield put(getApartmentNo(filterRecords))
  } catch (error) {}
}

function* searchApartmentNoHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/all/apartments?key=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.identifier,
    }))
    yield put(getSearchApartmentNo(filterRecords))
  } catch (error) {}
}
function* showApartmentNoSingleHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartments`, {
        building_id: [payload.id],
      })
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.identifier,
    }))

    yield put(getApartmentNoSingle(filterRecords))
  } catch (error) {}
}

function* searchApartmentNoSingleHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/apartments?key=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getSearchApartmentNoSingle(filterRecords))
  } catch (error) {}
}
// APARTMENT NO SECTION END
function* showTypeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/accounting/types?type=${payload.type}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getTypes(filterRecords))
  } catch (error) {}
}

function* searchTypeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/accounting/types?key=${payload.key}&type=${payload.searchPayload}`
      )
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getSearchTypes(filterRecords))
  } catch (error) {}
}
function* showDebtGroupHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/groups/all?type=${payload.type}`)
    )
    yield put(getDebtGroups(response.data.data))
  } catch (error) {}
}

function* searchDebtGroupHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/groups/all?key=${payload.key}&type=${payload.searchPayload}`
      )
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getSearchDebtGroups(filterRecords))
  } catch (error) {}
}

function* showCurrentHandler() {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/current/accounts`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getCurrents(filterRecords))
  } catch (error) {}
}

function* searchCurrentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/current/accounts?key=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: item.name,
    }))
    yield put(getSearchCurrent(filterRecords))
  } catch (error) {}
}

function* showResidentHandler() {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident/not/paginate`)
    )

    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: `${item.name} ${item.surname}`,
    }))
    yield put(getResidents(filterRecords))
  } catch (error) {}
}

function* searchResidentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident/all`, {
        name: payload.key,
      })
    )

    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: `${item.name} ${item.surname}`,
    }))
    yield put(getSearchResident(filterRecords))
  } catch (error) {}
}

function* showSmsTemplateHandler() {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/sms/templates`)
    )
    const filterRecords = response.data.data.map((item) => ({
      id: item.id,
      name: `${item.name}`,
      type: item.sms_template_type,
      message: item.message,
    }))
    yield put(getSmsTemplate(filterRecords))
  } catch (error) {}
}

function* searchSmsTemplateHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/sms/templates?name=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: `${item.name} ${item.surname}`,
      type: item.sms_template_type,
      message: item.message,
    }))
    yield put(getSearchSmsTemplate(filterRecords))
  } catch (error) {}
}

function* showStaffHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/staffs`))
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: `${item.name}`,
    }))
    yield put(getStaff(filterRecords))
  } catch (error) {}
}

function* searchStaffHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/staffs?name=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: `${item.name}`,
    }))
    yield put(getSearchStaff(filterRecords))
  } catch (error) {}
}

function* showSurveyHandler() {
  try {
    const response = yield call(() => axios.get(`${application.api}/surveys`))
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: `${item.title}`,
    }))
    yield put(getSurvey(filterRecords))
  } catch (error) {}
}

function* searchSurveyHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/surveys?name=${payload.key}`)
    )
    const filterRecords = response.data.data.Records.map((item) => ({
      id: item.id,
      name: `${item.title}`,
    }))
    yield put(getSearchSurvey(filterRecords))
  } catch (error) {}
}

export function* rootSearchSagas() {
  yield all([
    takeEvery(SelectServiceTypes.SHOW_SEARCH_ACCOUNT_KEY, searchAccountHandler),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_ACCOUNT, showAccountHandler),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_BUILDING_KEY,
      searchBuildingHandler
    ),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_BUILDING, showBuildingHandler),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_APARTMENT_KEY,
      searchApartmentHandler
    ),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_APARTMENT, showApartmentHandler),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_APARTMENT_SINGLE_KEY,
      searchApartmentSingleHandler
    ),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_APARTMENT_SINGLE,
      showApartmentSingleHandler
    ),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_TYPE_KEY, searchTypeHandler),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_TYPE, showTypeHandler),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_DEBT_GROUP_KEY,
      searchDebtGroupHandler
    ),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_DEBT_GROUP, showDebtGroupHandler),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_CURRENT_KEY, searchCurrentHandler),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_CURRENT, showCurrentHandler),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_RESIDENTS_KEY,
      searchResidentHandler
    ),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_RESIDENTS, showResidentHandler),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_SMS_TEMPLATES_KEY,
      searchSmsTemplateHandler
    ),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_SMS_TEMPLATES,
      showSmsTemplateHandler
    ),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_STAFF_KEY, searchStaffHandler),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_STAFF, showStaffHandler),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_SURVEY_KEY, searchSurveyHandler),
    takeEvery(SelectServiceTypes.SHOW_SEARCH_SURVEY, showSurveyHandler),

    //APARTMENT NO TYPES
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_APARTMENT_NO,
      showApartmentNoHandler
    ),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_APARTMENT_NO_KEY,
      searchApartmentNoHandler
    ),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_APARTMENT_NO_SINGLE,
      showApartmentNoSingleHandler
    ),
    takeEvery(
      SelectServiceTypes.SHOW_SEARCH_APARTMENT_NO_SINGLE_KEY,
      searchApartmentNoSingleHandler
    ),
  ])
}
