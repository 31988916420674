import axios from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { ApartmentTypes } from './types'
import toast from 'react-hot-toast'
import {
  deleteApartmentOwn,
  showApartment,
  showApartmentDetail,
  showApartmentOwn,
  showApartmentDebts,
  addStockHolder,
  showStockHolder,
  updateStockHolder,
  showApartmentDebtGroups,
  updateSmsCode,
  addExcelApartmentError,
  filterLoad,
  showApartmentDebtsDetail,
  handleUserPost,
  showApartmentCollections,
  showAllApartmentDebts,
} from './slice'
function* showApartmentHandler({ page, payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/all/apartments?page=${page}`, payload)
    )
    yield put(showApartment(response.data.data.Records))
    yield put(filterLoad(false))
  } catch (error) {}
}

function* showApartmentDetailHandler({ id }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/apartment?id=${id}`)
    )
    yield put(showApartmentDetail(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showApartmentDebtHandler({ payload, page }) {
  console.log('geldi')
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/apartment/debts?apartment_id=${payload.id}&page=${page}`
      )
    )
    yield put(showApartmentDebts(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showAllApartmentDebtHandler({ payload }) {
  console.log('TÜM BORÇ DATASI GELDİ')
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/apartment/debts?apartment_id=${payload.id}&is_print=true`
      )
    )
    yield put(showAllApartmentDebts(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showApartmentCollectionHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/apartment/debt/payments?apartment_id=${payload.id}&page=${page}`
      )
    )
    yield put(showApartmentCollections(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteApartmentDebtHandler({ payload, page }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/delete/debt/single`, {
        data: {
          id: payload.id,
          resident_id: payload.resident_id,
          page,
        },
      })
    )
    yield showApartmentDebtHandler({
      payload: { id: payload.apartment_id },
      page: payload.page,
    })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showApartmentDebtDetailsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/debt/payments?debt_id=${payload.debt_id}`)
    )
    yield put(showApartmentDebtsDetail(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showApartmentDebtGroupsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/debt/groups?apartment_id=${payload.id}`)
    )
    yield put(showApartmentDebtGroups(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* addApartmentDebtGroupsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/debt/group`, {
        group_id: payload.group_id,
        apartment_id: parseInt(payload.id),
      })
    )
    yield showApartmentDebtGroupsHandler({ payload })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteApartmentDebtGroupsHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/debt/group`, {
        data: {
          id: payload.group_id,
        },
      })
    )
    yield showApartmentDebtGroupsHandler({ payload })

    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showApartmentOwnHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/apartment`, {
        params: {
          apartment_id: payload.id,
          page: payload.page,
          //type: 1,
        },
      })
    )
    yield put(showApartmentOwn(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* showApartmentTenantHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/apartment`, {
        params: {
          apartment_id: payload.id,
          page: payload.page,
          //type: 2,
        },
      })
    )
    //yield put(showApartmentTenant(response.data.data.Records))
    yield put(showApartmentOwn(response.data.data.Records))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* addApartmentResidentsHandler({ payload }) {
  payload.data.type = payload.type
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/resident/apartment`, payload.data)
    )
    toast.success(response.data.description)
    // tekrar istek göndermremiz lazım çünkü güncelledikten sonra veriler tam donmuyor
    const newPayload = {
      payload: {
        page: 1,
        id: payload.data.apartment_id,
        //type: payload.type,
      },
    }
    yield showApartmentOwnHandler(newPayload)
    yield put(handleUserPost(true))
  } catch (error) {
    yield put(handleUserPost(false))
    toast.error(error.response.data.description)
  }
}

function* deleteApartmentResidentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/resident/apartment`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield put(deleteApartmentOwn(payload.id))
    // tekrar istek göndermremiz lazım çünkü güncelledikten sonra veriler tam donmuyor
    const newPayload = {
      payload: {
        page: 1,
        id: payload.apartment_id,
        type: payload.type,
      },
    }
    yield showApartmentOwnHandler(newPayload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* deleteApartmentTenantHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/resident/apartment`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield put(deleteApartmentOwn(payload.id))
    // tekrar istek göndermremiz lazım çünkü güncelledikten sonra veriler tam donmuyor
    const newPayload = {
      payload: {
        page: 1,
        id: payload.apartment_id,
        type: payload.type,
      },
    }
    yield showApartmentTenantHandler(newPayload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* newApartmentHandler({ payload, page, filter_payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartment`, payload)
    )
    yield showApartmentHandler({ page, filter_payload })
    //yield put(addApartment(response.data.data));
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateApartmentHandler({ payload }) {
  payload.group_id = (payload.group_id && payload.group_id) || undefined
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/apartment`, payload)
    )
    // // yield put(deleteApartment(id))
    // yield showApartmentHandler({ page });
    yield showApartmentDetailHandler({ id: payload.id })
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* delSmsCodeHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/apartment/delete/request?id=${payload.apartment_id}`
      )
    )
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* deleteApartmentHandler({ id, page, code }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/apartment`, {
        data: {
          id: id,
          code: code,
        },
      })
    )
    // yield put(deleteApartment(id))
    yield showApartmentHandler({ page })
    yield put(updateSmsCode(true))
    toast.success(response.data.description)
  } catch (error) {
    yield put(updateSmsCode(false))
    toast.error(error.response.data.description)
  }
}

function* showStockHolderHandler({ apartment_id, building_id }) {
  try {
    const response = yield call(() =>
      axios.get(
        `${application.api}/stock/holders?apartment_id=${apartment_id}&building_id=${building_id}`
      )
    )
    yield put(showStockHolder(response.data.data))
  } catch (error) {}
}

function* addStockHolderHandler({ id }) {
  try {
    const response = yield call(() =>
      axios.get(`${application.api}/resident/single?id=${id}`)
    )

    yield put(addStockHolder(response.data.data))
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}
function* addStockHolderByResidentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/stock/holder/by/resident`, payload.data)
    )
    yield showStockHolderHandler(payload.data)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addStockHolderNewHandler({ payload }) {
  payload.data.type = 3
  payload.data.phone = payload.data.phone.replaceAll(' ', '')
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/stock/holder`, payload.data)
    )
    yield showStockHolderHandler(payload.data)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateStockHolderHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.put(`${application.api}/stock/holder/percent`, {
        apartment_id: payload.apartment_id,
        building_id: payload.building_id,
        stock_holders: payload.stockHolders.map((item) => ({
          percent: item.percent,
          stock_holder_id: item.id,
        })),
      })
    )
    yield put(updateStockHolder(payload))
    const newPayload = {
      payload: {
        id: payload.apartment_id,
        page: 1,
        type: 1,
      },
    }
    yield showApartmentOwnHandler(newPayload)
    toast.success(response.data.description)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* updateStockHolderSliceHandler({ payload }) {
  try {
    yield put(updateStockHolder(payload))
  } catch (error) {}
}

function* delStockHolderHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.delete(`${application.api}/stock/holder`, {
        data: {
          apartment_id: payload.apartment_id,
          stock_holder_id: payload.stock_holder_id,
          building_id: payload.building_id,
        },
      })
    )
    toast.success(response.data.description)

    yield showStockHolderHandler(payload)
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* showFilterApartmentHandler({ payload, page }) {
  // uzunkupu sıfır olanları null olarak backende gönderiyoruz.
  payload.building_id =
    payload.building_id.length === 0 ? null : payload.building_id
  payload.group_id = payload.group_id.length === 0 ? null : payload.group_id
  payload.area = payload.area.length === 0 ? null : payload.area
  payload.land = payload.land.length === 0 ? null : payload.land
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/all/apartments?page=${page}`, {
        building_id: payload.building_id,
        group_id: payload.group_id,
        identifier: payload.identifier,
        area: payload.area,
        land: payload.land,
      })
    )
    yield put(showApartment(response.data.data.Records))
  } catch (error) {}
}

function* showFilterLoadHandler() {
  yield put(filterLoad(true))
}

function* addPaymentDebtHandler(data) {
  const payload = {
    account_id: data.payload.account_id,
    amount: parseFloat(data.payload.amount),
    date: data.payload.date,
    debt_id: data.payload.id,
    description: data.payload.description,
    accounting_type_id: data.payload.accounting_type_id,
  }
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/payment/debt`, payload)
    )
    toast.success(response.data.description)
    const newPayload = {
      id: data.apartment_id,
      page: 1,
    }
    yield showApartmentDebtHandler({ payload: newPayload })
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

function* addExcelApartmentHandler({ payload }) {
  try {
    const response = yield call(() =>
      axios.post(`${application.api}/apartment/multi`, payload)
    )
    toast.success(response.data.description)
    yield put(addExcelApartmentError(response.data.data))
  } catch (error) {
    toast.error(error.response.data.description)
  }
}

export function* ApartmentSagas() {
  yield all([
    takeEvery(ApartmentTypes.SHOW_APARTMENT, showApartmentHandler),
    takeEvery(ApartmentTypes.ADD_APARTMENT, newApartmentHandler),
    takeEvery(ApartmentTypes.DELETE_APARTMENT, deleteApartmentHandler),
    takeEvery(ApartmentTypes.UPDATE_APARTMENT, updateApartmentHandler),
    takeEvery(ApartmentTypes.SHOW_APARTMENT_DETAIL, showApartmentDetailHandler),
    takeEvery(ApartmentTypes.SHOW_APARTMENT_OWN, showApartmentOwnHandler),
    takeEvery(
      ApartmentTypes.ADD_APARTMENT_RESIDENT,
      addApartmentResidentsHandler
    ),
    takeEvery(
      ApartmentTypes.DELETE_APARTMENT_OWN,
      deleteApartmentResidentHandler
    ),
    takeEvery(ApartmentTypes.SHOW_APARTMENT_TENANT, showApartmentTenantHandler),
    takeEvery(
      ApartmentTypes.DELETE_APARTMENT_TENANT,
      deleteApartmentTenantHandler
    ),
    takeEvery(ApartmentTypes.SHOW_APARTMENT_DEBT, showApartmentDebtHandler),
    takeEvery(
      ApartmentTypes.SHOW_ALL_APARTMENT_DEBT,
      showAllApartmentDebtHandler
    ),
    takeEvery(
      ApartmentTypes.SHOW_APARTMENT_COLLECTIONS,
      showApartmentCollectionHandler
    ),
    takeEvery(ApartmentTypes.DELETE_APARTMENT_DEBT, deleteApartmentDebtHandler),

    takeEvery(
      ApartmentTypes.SHOW_APARTMENT_DEBT_DETAIL,
      showApartmentDebtDetailsHandler
    ),

    takeEvery(
      ApartmentTypes.SHOW_APARTMENT_DEBT_GROUPS,
      showApartmentDebtGroupsHandler
    ),
    takeEvery(
      ApartmentTypes.ADD_APARTMENT_DEBT_GROUPS,
      addApartmentDebtGroupsHandler
    ),
    takeEvery(
      ApartmentTypes.DELETE_APARTMENT_DEBT_GROUPS,
      deleteApartmentDebtGroupsHandler
    ),
    takeEvery(ApartmentTypes.SHOW_STOCK_HOLDER, showStockHolderHandler),
    takeEvery(ApartmentTypes.ADD_STOCK_HOLDER, addStockHolderHandler),
    takeEvery(ApartmentTypes.ADD_STOCK_HOLDER_NEW, addStockHolderNewHandler),
    takeEvery(ApartmentTypes.UPDATE_STOCK_HOLDER, updateStockHolderHandler),
    takeEvery(
      ApartmentTypes.UPDATE_STOCK_HOLDER_SLICE,
      updateStockHolderSliceHandler
    ),
    takeEvery(
      ApartmentTypes.ADD_STOCK_HOLDER_BY_RESIDENT,
      addStockHolderByResidentHandler
    ),
    takeEvery(ApartmentTypes.DELETE_STOCK_HOLDER, delStockHolderHandler),
    takeEvery(
      ApartmentTypes.SHOW_FILTER_APARTMENTS,
      showFilterApartmentHandler
    ),
    takeEvery(ApartmentTypes.SHOW_APARTMENT, showFilterLoadHandler),
    takeEvery(ApartmentTypes.ADD_PAYMENT_DEBT_APARTMENT, addPaymentDebtHandler),
    takeEvery(ApartmentTypes.SEND_APARTMENT_DELETE_CODE, delSmsCodeHandler),
    takeEvery(ApartmentTypes.ADD_EXCEL_APARTMENT, addExcelApartmentHandler),
  ])
}
